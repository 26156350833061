import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchJobInstallerPayments = async (jobId: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(
      apiUrl + `/jobs/${jobId}/installer-payments`
    );
    console.log("installer payments fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchInstallerPayments " + err);
    return { error: err.message };
  }
};

export const fetchInstallerPayments = async (params: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + `/installer-payments`, params);
    console.log("installer payments fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchInstallerPayments " + err);
    return { error: err.message };
  }
};

export const fetchInstallerPayment = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + `/installer-payments/${id}`);
    console.log("installer payment fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchInstallerPayment " + err);
    return { error: err.message };
  }
};

export const upsertInstallerPayment = async (data: any) => {
  try {
    console.log("data: ", data);
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + `/installer-payments`, data);
    console.log("installer payment saved ");
    return resp;
  } catch (err: any) {
    console.log("error on saveInstallerPayment " + err);
    return { error: err.message };
  }
};

export const updateInstallerPayment = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(
      apiUrl + `/installer-payments/${data.id}`,
      data
    );
    console.log("installer payment updated ");
    return resp;
  } catch (err: any) {
    console.log("error on updateInstallerPayment " + err);
    return { error: err.message };
  }
};

export const deleteInstallerPayment = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + `/installer-payments/${id}`);
    console.log("installer payment deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deleteInstallerPayment " + err);
    return { error: err.message };
  }
};
