import { Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsPencilFill } from "react-icons/bs";
import { RiDraggable } from "react-icons/ri";
import FormInputsCreateDialog from "./FormInputsCreateDialog";
import { IFormInput } from "./types";

interface IProps {
  formInputs: any[];
  type: string;
  onListUpdated: (formInputs: any[], type: string) => void;
}

const FormInputsList: React.FC<IProps> = (props) => {
  const [search, setSearch] = useState("");
  const [formInputs, setFormInputs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFormInput, setSelectedFormInput] = useState<any>({});

  useEffect(() => {
    const inputs = props.formInputs || [];
    setFormInputs(inputs);
  }, [props.formInputs]);

  function showEditDialog(id: any): void {
    const formInput = props.formInputs.find((item: any) => item.id === id);
    setSelectedFormInput(formInput);
    setShowModal(true);
  }

  function handleSearchChanged(event: any): void {
    setSearch(event.target.value);
    const searchValue = event.target.value.toLowerCase();
    const filteredList = props.formInputs?.filter((item: any) => {
      return item.name.toLowerCase().includes(searchValue);
    });
    setFormInputs(filteredList);
  }

  function handleOnSaved(formInput: IFormInput): void {
    const newFormInputs = formInputs.map((item: any) => {
      if (item.id === formInput.id) {
        return formInput;
      }
      return item;
    });
    props.onListUpdated(newFormInputs, props.type);
  }

  return (
    <>
      <FormInputsCreateDialog
        formInput={selectedFormInput}
        type={props.type}
        show={showModal}
        onHide={() => setShowModal(false)}
        onSaved={handleOnSaved}
      />
      <div className="p-3">
        <div className="row my-3 ml-3" style={{ maxWidth: 400 }}>
          <TextField
            id="filled-search"
            label="Search"
            type="search"
            variant="outlined"
            onChange={handleSearchChanged}
            value={search}
          />
        </div>
        <div className="row mt-3">
          {formInputs.map((item: any) => (
            <div>
              <Paper
                elevation={3}
                key={item.id}
                className="p-2 my-1 ml-3 sort-item"
                style={{ width: 600 }}
              >
                <div className="d-flex">
                  <div>
                    <RiDraggable />
                  </div>
                  <div className="flex-grow-1 fw-bold mt-1 ml-3">
                    <div className={item.active ? "" : "text-black-50"}>
                      {item.name}
                    </div>
                  </div>
                  <div>
                    <span
                      className={`badge rounded-pill ${
                        item.active == false
                          ? "text-bg-danger"
                          : "text-bg-success"
                      }`}
                    >
                      {item.active == false ? "Inactive" : "Active"}
                    </span>
                  </div>
                  <div className="sort-item-actions">
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm mr-2"
                        title="edit template"
                        onClick={() => showEditDialog(item.id)}
                      >
                        <BsPencilFill />
                      </button>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FormInputsList;
