import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchNotes = async (params = {}) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/notes", params);
    console.log("notes fetched ", data.data?.length);
    return data;
  } catch (err: any) {
    console.log("error on fetchNotes " + err);
    return { error: err.message };
  }
};

export const fetchNote = async (id: string) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/notes/" + id);
    console.log("note fetched ");
    return { data };
  } catch (err) {
    console.log("error on fetchNote " + err);
    return { error: err };
  }
};

export const createNote = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/notes", data);
    console.log("note saved ");
    return { data: resp };
  } catch (err: any) {
    console.log("error on saveNote " + err);
    return { error: err };
  }
};

export const deleteNote = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/notes/" + id);
    console.log("note deleted ");
    return { data: resp };
  } catch (err: any) {
    console.log("error on deleteNote " + err);
    return { error: err };
  }
};
