import React, { useEffect, useState } from "react";
import { arraysAreSimilar } from "../../../common/utils";
import { saveJobFeatures } from "../../../services/jobApiService";
import { IFeature } from "../types";
import { usePanelsContext } from "../../../context/PanelsContext";

interface IProps {
  jobId: string;
  features: any[];
  jobFeatures: any[];
  closePane: () => void;
  updateJobFeatures: (features: any[]) => void;
  setLoading?: (loading: boolean) => void;
}

const JobFeaturesCB: React.FC<IProps> = (props) => {
  const { jobId, features } = props;
  const [jobFeatures, setJobFeatures] = useState<any[]>([]);
  const jobFeatureIds = getFeatureIds();
  const [checkedItems, setCheckedItems] = useState<any>({});

  const { setPanelDisplay } = usePanelsContext();

  useEffect(() => {
    setJobFeatures(props.jobFeatures);
  }, []);

  useEffect(() => {
    if (!jobFeatures) return;
    checkItems(jobFeatures);
  }, [jobFeatures]);

  function getFeatureIds(): any[] {
    const jobFeatureIds = jobFeatures
      ?.filter((item: IFeature) => item.pivot.active == 1)
      .map((feature: any) => feature.id);
    return jobFeatureIds;
  }

  function handleClosePane() {
    setPanelDisplay("features", false);
  }

  async function updateFeatures(e: any): Promise<void> {
    e.preventDefault();
    const checkedItemIds = getCheckedItemIds();
    console.log("applyFeatures: ", checkedItemIds);

    if (!arraysAreSimilar(checkedItemIds, jobFeatureIds)) {
      props.setLoading(true);

      console.log("updateJobFeatures: ", checkedItemIds);
      const data = {
        job_id: jobId,
        feature_ids: checkedItemIds,
      };
      const resp = await saveJobFeatures(jobId, data);

      console.log("resp: ", resp);

      if (resp.error) {
        const message = resp.error.message;
        console.log("error updating features: ", message);
        //createToastNotification(message);
      } else {
        console.log("features updated successfully");
        //createToastNotification(message);
        //setLeadFeatures(resp.data.features);
        props.updateJobFeatures(resp.data.features);
        handleClosePane();
      }

      props.setLoading(false);
    }
  }

  const handleChange = (event: any) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  function getCheckedItemIds(): any[] {
    const checkedKeys = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    );
    return checkedKeys;
  }

  function createLeadFeature(event: any): void {
    console.log("createLeadFeature");
  }

  function checkItems(jobFeatures: any[]) {
    const checkedItems: any = {};
    for (const feature of jobFeatures) {
      if (feature.pivot.active == 1) checkedItems[feature.id] = true;
    }
    setCheckedItems(checkedItems);
  }
  return (
    <div>
      <ul className="list-group">
        {features.map((feature: any) => (
          <li key={feature.id} className="list-group-item">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  className="mr-3"
                  name={feature.id}
                  checked={checkedItems[feature.id]}
                  onChange={handleChange}
                />
                {feature.name}
              </label>
            </div>
          </li>
        ))}
        <li key="divider" className="dropdown-divider"></li>
        <li key="apply" className="list-group-item">
          <div className="col-md-12 col-lg-12 text-center">
            <a
              className="btn btn-primary btn-sm"
              onClick={updateFeatures}
              href="#"
            >
              Save
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default JobFeaturesCB;
