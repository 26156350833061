import React from "react";
import JobsDataGrid from "./JobsDataGrid";

interface Props {
  name?: string;
}

const JobList: React.FC<Props> = () => {
  return (
    <>
      <JobsDataGrid />
    </>
  );
};

export default JobList;
