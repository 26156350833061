import React, { useEffect, useState } from "react";
import {
  adaptiveCurrencyFormat,
  arraysAreSimilar,
} from "../../../common/utils";
import { saveJobChecklist } from "../../../services/jobApiService";
import { IChecklist } from "../../settings/checklist/types";
import { usePanelsContext } from "../../../context/PanelsContext";

interface IProps {
  jobId: string;
  checklist: any[];
  jobChecklist: any[];
  updateJobChecklist: (checklist: any[]) => void;
  setLoading?: (loading: boolean) => void;
}

const JobChecklistCB: React.FC<IProps> = (props) => {
  const { jobId, checklist } = props;
  const [jobChecklist, setJobChecklist] = useState<any[]>([]);
  const jobChecklistIds = jobChecklist?.map((checklist: any) => checklist.id);
  const [checkedItems, setCheckedItems] = useState<any>({});
  const { setPanelDisplay } = usePanelsContext();

  useEffect(() => {
    setJobChecklist(props.jobChecklist);
  }, []);

  useEffect(() => {
    if (!jobChecklist) return;
    checkItems(jobChecklist);
  }, [jobChecklist]);

  function handleClosePane() {
    setPanelDisplay("checklist", false);
  }

  async function updateChecklist(): Promise<void> {
    const checkedItemIds = getCheckedItemIds();
    console.log("applyChecklist: ", checkedItemIds);

    if (!arraysAreSimilar(checkedItemIds, jobChecklistIds)) {
      props.setLoading(true);

      console.log("updateJobChecklist: ", checkedItemIds);
      const data = {
        job_id: jobId,
        checklist_ids: checkedItemIds,
      };
      const resp = await saveJobChecklist(jobId, data);

      console.log("resp: ", resp);

      if (resp.error) {
        const message = resp.error.message;
        console.log("error updating checklist: ", message);
        //createToastNotification(message);
      } else {
        console.log("checklist updated successfully");
        //createToastNotification(message);
        //setJobChecklist(resp.data.checklist);
        props.updateJobChecklist(resp.data.checklist);
        handleClosePane();
      }

      props.setLoading(false);
    }
  }

  const handleChange = (event: any) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  function getCheckedItemIds(): any[] {
    const checkedKeys = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    );
    return checkedKeys;
  }

  function checkItems(jobChecklist: any[]) {
    const checkedItems: any = {};
    for (const label of jobChecklist) {
      checkedItems[label.id] = true;
    }
    setCheckedItems(checkedItems);
  }

  function getRepItems(): IChecklist[] {
    return checklist?.filter((d) => d.type == "reps" || !d.type);
  }

  function getOfficeItems(): IChecklist[] {
    return checklist?.filter((d) => d.type == "office");
  }

  function calculateCompletion(type = "reps") {
    const checklist = type === "reps" ? getRepItems() : getOfficeItems();
    const totalTasks = checklist.length;
    const completedTasks = checklist.filter(
      (task) => checkedItems[task.id]
    ).length;
    const completionPercentage = (completedTasks / totalTasks) * 100;
    return completionPercentage.toFixed(0);
  }

  return (
    <div>
      <ul className="list-group">
        <li key="header" className="list-group-item fw-light">
          <div className="checkbox">
            <label>Sales Rep {calculateCompletion()}%</label>
          </div>
        </li>
        {getRepItems().map(({ id, name }) => (
          <li key={id} className="list-group-item">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  className="mr-3"
                  name={id}
                  checked={checkedItems[id]}
                  onChange={handleChange}
                />
                {name}
              </label>
            </div>
          </li>
        ))}
        <li key="header" className="list-group-item fw-light">
          <label>Office {calculateCompletion("office")}%</label>
        </li>
        {getOfficeItems().map(({ id, name }) => (
          <li key={id} className="list-group-item">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  className="mr-3"
                  name={id}
                  checked={checkedItems[id]}
                  onChange={handleChange}
                />
                {name}
              </label>
            </div>
          </li>
        ))}
        <li key="divider" className="dropdown-divider"></li>
        <li key="apply" className="list-group-item">
          <div className="col-md-12 col-lg-12 text-center">
            <a
              className="btn btn-primary btn-sm"
              onClick={updateChecklist}
              href="#"
            >
              Save
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default JobChecklistCB;
