import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchAuthUrl = async (name: string) => {
  try {
    const params = {
      origin: window.location.href,
    };
    const apiService = new ApiService();
    const data = await apiService.get(
      `${apiUrl}/integrations/${name}/auth-url`,
      params
    );
    console.log("auth url fetched ", data);
    return data;
  } catch (err: any) {
    console.log("error on fetchAuthUrl " + err);
    return { error: err.message };
  }
};

export const fetchIntegrations = async (name: string) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/integrations/" + name);
    console.log("integrations fetched ", data.data?.length);
    return data;
  } catch (err: any) {
    console.log("error on fetchIntegrations " + err);
    return { error: err.message };
  }
};

export const updateIntegration = async (id: string, data = {}) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(apiUrl + "/integrations/" + id, data);
    console.log("integration updated", data);
    return resp;
  } catch (err: any) {
    console.log("error on updateIntegration " + err);
    return { error: err.message };
  }
};

export const updateGoogleMapsAPIKey = async (apiKey: string) => {
  const apiService = new ApiService();
  const resp = await apiService.post(apiUrl + "/integrations/google-maps", {
    apiKey,
  });
  return resp;
};

export const authorizeIntegration = async (name: string) => {
  try {
    const params = {
      origin: window.location.href,
    };
    const apiService = new ApiService();
    const data = await apiService.get(
      `${apiUrl}/integrations/${name}/auth`,
      params
    );
    console.log("integration connected ", data);
    return data;
  } catch (err: any) {
    console.log("error on connectIntegration " + err);
    return { error: err.message };
  }
};

export const deleteIntegration = async (id: string) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.delete(`${apiUrl}/integrations/${id}`);
    console.log("integration deleted ", data);
    return data;
  } catch (err: any) {
    console.log("error on deleteIntegration " + err);
    return { error: err.message };
  }
};
