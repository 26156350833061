import React, { ReactElement } from "react";
import { ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import { TiTabsOutline } from "react-icons/ti";
import { TbDoorExit } from "react-icons/tb";
import { baseUrl } from "../../../config/app";

// https://react-pdf-viewer.dev/plugins/default-layout/

function handlePdfClick() {
  let path = window.location.pathname;
  window.open(baseUrl + path, "_blank");
}

function handleGoBackClick() {
  window.history.back();
}

export const PdfViewerToolbar = (
  Toolbar: (props: ToolbarProps) => ReactElement
) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      const {
        CurrentPageInput,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        Zoom,
        ZoomIn,
        ZoomOut,
      } = slots;
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <div style={{ padding: "0px 2px" }}>
            <ZoomOut />
          </div>
          <div style={{ padding: "0px 2px" }}>
            <Zoom />
          </div>
          <div style={{ padding: "0px 2px" }}>
            <ZoomIn />
          </div>
          <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
            <GoToPreviousPage />
          </div>
          <div className="d-flex d-inline ml-2" style={{ padding: "0px 2px" }}>
            <CurrentPageInput />
            <div className="mt-1 mr-2">
              /<NumberOfPages />
            </div>
          </div>
          <div style={{ padding: "0px 2px" }}>
            <GoToNextPage />
          </div>
          <div style={{ padding: "0px 0px", marginLeft: "auto" }}>
            <button
              type="button"
              className="btn"
              onClick={handleGoBackClick}
              title="Go Back"
            >
              <TbDoorExit /> Close
            </button>
          </div>
          <div style={{ padding: "0px 10px" }}>
            <button
              type="button"
              className="btn"
              onClick={handlePdfClick}
              title="open new tab"
            >
              <TiTabsOutline />
            </button>
          </div>
        </div>
      );
    }}
  </Toolbar>
);
