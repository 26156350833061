import React from "react";

const PowerBI: React.FC = () => {
  return (
    <>
      <div className="p-3 mr-5">
        <div className="row">
          <div className="page-title">Power BI</div>
          <div className="mr-5 mt-3">
            <iframe
              title="Sales"
              width="1140"
              height="541.25"
              src="https://app.powerbi.com/reportEmbed?reportId=2195ce41-3fe3-4123-945f-617b5990928d&autoAuth=true&ctid=0e50b3ec-f04a-4195-a60a-4d8364ebb529"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default PowerBI;
