import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  addJobSketch,
  deleteJobSketch,
  fetchJobSketch,
  updateJobSketch,
} from "../../../services/jobSketch";
import { Modal, Button } from "react-bootstrap";
import JobSketchList from "./JobSketchList";
import { IJob, ISketch } from "../types";
import { pick } from "lodash";
import ConfirmDialog from "../../common/ConfirmDialog";
import ImageViewer from "../../common/ImageViewer";
import { formatErrorMessage, isIOS } from "../../../common/utils";
import { toast } from "react-toastify";
import PDFViewer from "./PDFViewer";
import PDFPreview from "../../common/PDFPreview";

interface IProps {
  job: IJob;
  setLoading?: (loading: boolean) => void;
}

const JobSketches: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [job, setJob] = useState<any>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState<string>("");
  const [selected, setSelected] = useState<ISketch>({} as ISketch);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [imageViewerSource, setImageViewerSource] = useState("");
  const [pdfViewerSource, setPdfViewerSource] = useState("");

  useEffect(() => {
    setJob(props.job || {});
  }, [props.job]);

  const {
    register,
    reset,
    getValues,
    formState: { isDirty, dirtyFields },
  } = useForm<any>();

  async function handleSubmitClick() {
    const data = getValues();
    if (!isEditMode) {
      await handleSubmit_Add(data);
    } else {
      await handleSubmit_Update(data);
    }
  }

  async function handleSubmit_Add(data: any) {
    const file = data?.file?.[0];
    let errors = [];

    if (!data.label) {
      errors.push("Label is required");
    }

    if (!file) {
      errors.push("File is required");
    } else if (file.size > 1 * 1024 * 1024) {
      errors.push("File size should not exceed 1MB");
    }

    if (errors.length > 0) {
      setErrors(errors.join(", "));
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("label", data.label);
    formData.append("visibility", data.visibility);

    setShowModal(false);
    props.setLoading(true);

    const resp = await addJobSketch(job.id, formData);

    if (resp.data) {
      const sketch = resp.data;
      const sketches = [...job.sketches, sketch];

      setJob({ ...job, sketches });
      reset();
    } else if (resp.error) {
      console.log(resp.error);
      setErrors(resp.error);
      toast.error("Error adding sketch: " + formatErrorMessage(resp.error));
    }

    props.setLoading(false);
  }

  async function handleSubmit_Update(data: any): Promise<void> {
    if (!data.id) {
      console.log("no sketch id");
      return;
    }
    if (data.label.trim() === "") {
      setErrors("Label is required");
      return;
    }
    setShowModal(false);
    props.setLoading(true);
    const _data = pick(data, ["id", "label", "visibility"]);
    const resp = await updateJobSketch(_data);

    if (resp.data) {
      const updated = resp.data;
      const sketches = job.sketches.map((sketch: any) =>
        sketch.id == data.id ? updated : sketch
      );
      setJob({ ...job, sketches });
      reset();
    } else if (resp.error) {
      toast.error("Error updating sketch: " + formatErrorMessage(resp.error));
      setErrors(resp.error);
      console.log("sketch update error: ", resp.error);
    }

    props.setLoading(false);
  }

  function handleClose(): void {
    setShowModal(false);
  }

  function handleAddSketch(e: any): void {
    e.preventDefault();
    setErrors("");
    setIsEditMode(false);
    reset({
      label: "",
      visibility: 1,
      id: "",
      file: "",
    });
    setShowModal(true);
  }

  function editSketch(id: any): void {
    setIsEditMode(true);
    setErrors("");
    const sketch = job.sketches?.find((sketch: any) => sketch.id == id);
    reset(sketch);
    setShowModal(true);
  }

  async function deleteSketch(id: any) {
    console.log("delete sketch ", id);
    const sketch = job.sketches?.find((sketch: any) => sketch.id == id);
    setSelected(sketch);
    setShowDeleteModal(true);
  }

  async function handleDeleteClick() {
    setShowDeleteModal(false);
    props.setLoading(true);

    const resp = await deleteJobSketch(selected.id);
    if (resp.result) {
      const sketches = job.sketches.filter(
        (sketch: any) => sketch.id != selected.id
      );
      setJob({ ...job, sketches });
    } else {
      console.log("delete sketch error: ", resp.error);
    }

    props.setLoading(false);
  }

  function handleOnHide(e: any): void {
    console.log("handleOnHide", e);
    setShowDeleteModal(false);
  }

  async function handlePreviewImage(id: any) {
    props.setLoading(true);
    const resp = await fetchJobSketch(id);
    props.setLoading(false);
    if (resp.data?.includes("/pdf;")) {
      setPdfViewerSource(resp.data);
      setIsPdfViewerOpen(true);
    } else {
      setImageViewerSource(resp.data);
      setIsImageViewerOpen(true);
    }
  }

  return (
    <div>
      <div className="row mb-3">
        <div className="">
          <label>Sketches </label>
          <a
            href="#"
            className="text-decoration-none ml-2"
            onClick={handleAddSketch}
          >
            Add Sketch
          </a>
        </div>
      </div>
      <>
        <Modal
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{isEditMode ? "Edit" : "Add"} Sketch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!isEditMode && (
              <div className="form-group row mb-2">
                <label htmlFor="inputFile" className="col-2 mt-2 ml-2">
                  File
                </label>
                <div className="col-sm-8">
                  <input type="file" id="inputFile" {...register("file")} />
                </div>
              </div>
            )}
            <div className="form-group row mb-2">
              <label htmlFor="label" className="col-2 mt-2 ml-2">
                Label
              </label>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control"
                  placeholder="installer"
                  spellCheck="false"
                  data-ms-editor="true"
                  {...register("label", { required: true })}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="label" className="col-2 mt-2 ml-2">
                Visibility
              </label>
              <div className="col-9">
                <select className="form-control" {...register("visibility")}>
                  <option value="0">Private</option>
                  <option value="1">Protected</option>
                  <option value="2">Public</option>
                </select>
              </div>
            </div>
            <div className="pl-2 text-danger" style={{ height: 16 }}>
              {errors}
            </div>
            <input type="hidden" {...register("id")} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmitClick}
              disabled={!isDirty}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <ConfirmDialog
          show={showDeleteModal}
          message={"Are you sure you want to delete? " + selected.label}
          onHide={handleOnHide}
          handleDeleteClick={handleDeleteClick}
        />

        <ImageViewer
          show={isImageViewerOpen}
          onClose={() => setIsImageViewerOpen(false)}
          src={imageViewerSource}
        />
        <>
          {isIOS() ? (
            <PDFViewer
              base64String={pdfViewerSource}
              show={isPdfViewerOpen}
              onClose={() => setIsPdfViewerOpen(false)}
            />
          ) : (
            <PDFPreview
              show={isPdfViewerOpen}
              onClose={() => setIsPdfViewerOpen(false)}
              base64String={pdfViewerSource}
            />
          )}
        </>
      </>
      <JobSketchList
        jobSketches={job.sketches}
        editSketch={editSketch}
        deleteSketch={deleteSketch}
        previewSketch={handlePreviewImage}
      />
    </div>
  );
};

export default JobSketches;
