import Pagination from "@mui/material/Pagination";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchFormFields, fetchLeads } from "../../services/leadApiService";
import { IMeta, IPageQuery } from "../common/types";
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
  Inject,
  Sort,
  Resize,
} from "@syncfusion/ej2-react-grids";
import ProgressSpinner from "../common/ProgressSpinner";
import { parseQueryStringToJSON } from "../../common/utils";
import { useQuery } from "@tanstack/react-query";

interface IRowData {
  id: number;
  name: string;
  air_date: string;
  episode: string;
}

export default function LeadDataGrid() {
  const [search, setSearch] = useState("");
  const [params, setParams] = useState({} as IPageQuery);
  const [form, setForm] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryString = parseQueryStringToJSON();
    let _params = { ...params, ...queryString };
    if (location.state) {
      _params = location.state;
      setSearch(_params.query || "");
    }
    setParams(_params);
    setPageLoaded(true);
  }, []);

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["leads", { params }],
    queryFn: () => fetchLeads(params),
    staleTime: Infinity,
    enabled: pageLoaded,
    retry: 1,
  });

  const { data: formData } = useQuery({
    queryKey: ["form-fields"],
    queryFn: fetchFormFields,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (formData?.data) {
      setForm(formData.data);
    }
  }, [formData]);

  function handleOnChange(event: any): void {
    setSearch(event.target.value);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }

  function handleSearchClick() {
    handleSearch();
  }

  function handleSearch() {
    if (search || params) {
      const query = {
        ...params,
        query: search,
        ...parseQueryStringToJSON(),
      };
      setParams(query);
    }
  }

  function handlePaginationChange(event: any, page: number) {
    const pagination = data.meta || ({} as IMeta);
    if (page == pagination.current_page) {
      return;
    }

    const params: IPageQuery = {
      page: page,
      query: pagination.query,
      sort_by: pagination.sort_by,
      sort_direction: pagination.sort_direction,
      size: pagination.per_page,
      ...parseQueryStringToJSON(),
    };
    setParams(params);
  }

  function handleClearClick() {
    setSearch("");
    const queryString = parseQueryStringToJSON();
    const params: any = {
      ...queryString,
      query: "",
    };
    setParams(params);
  }

  let grid: Grid | null;

  const rowSelected = () => {
    if (grid) {
      const selected: any[] = grid.getSelectedRecords();
      if (!selected?.length) {
        return;
      }
      const id = selected[0].id;
      const options = { state: params };
      console.log("history state", options);
      navigate(`/leads/${id}`, options);
    }
  };

  const rowClicked = () => {
    rowSelected();
  };

  function filterChanged(event: any, type: string): void {
    const value = event.target.value;
    console.log("filterChanged", value, type);
    if (!value) {
      removeKeyFomParams(type);
      return;
    }
    const filter: IPageQuery = {
      ...params,
      [type]: value,
      ...parseQueryStringToJSON(),
      page: 1,
    };
    console.log("filterChanged", filter);
    setParams(filter);
  }

  function removeKeyFomParams(key: string): void {
    const newParams: any = { ...params };
    delete newParams[key];
    setParams(newParams);
  }

  if (isError) {
    return (
      <div className="m-1">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>{error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <ProgressSpinner show={isLoading} />
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                value={search}
              />

              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleSearchClick}
                >
                  Search
                </button>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={handleClearClick}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-2 ml-1">Filters:</div>
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => filterChanged(e, "status")}
              value={params?.status || 0}
            >
              <option key="0" value="0">
                Status...
              </option>
              {form?.statuses?.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => filterChanged(e, "source")}
              value={params?.source || 0}
            >
              <option key="0" value="0">
                Source...
              </option>
              {form?.sources?.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => filterChanged(e, "label")}
              value={params?.label || 0}
            >
              <option key="0" value="0">
                Label...
              </option>
              {form?.labels?.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row my-3">
          <div className="row my-3">
            <div className="col-md-6">
              <Pagination
                count={data?.meta?.last_page}
                page={data?.meta?.current_page || 1}
                onChange={handlePaginationChange}
                shape="rounded"
              />
            </div>
            <div className="col d-flex justify-content-end mt-2 text-secondary">
              leads found: <span className="ml-1"> {data?.meta?.total}</span>
            </div>
          </div>
        </div>
        <div style={{ minWidth: 780 }}>
          <GridComponent
            dataSource={data?.data}
            allowSorting={true}
            allowResizing={true}
            onClick={rowClicked}
            ref={(g) => (grid = g)}
          >
            <ColumnsDirective>
              {/* <ColumnDirective field="id" headerText="ID" /> */}
              <ColumnDirective field="customer_name" headerText="Name" />
              <ColumnDirective field="email" headerText="Email" />
              <ColumnDirective field="phone" headerText="Phone" />
              <ColumnDirective field="street" headerText="Address" />
              <ColumnDirective field="city" headerText="City" />
            </ColumnsDirective>
            <Inject services={[Sort, Resize]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
}
