import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchPrices = async (id: string = "") => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/prices" + (id ? "/" + id : "");
    const resp = await apiService.get(url);
    return resp;
  } catch (err: any) {
    console.log("error on fetchPrices " + err);
    throw err;
  }
};

export const upsertPrice = async (params: any) => {
  const { data, isDeposit } = params;
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/prices" + (isDeposit === false ? "/others" : "");
    const resp = await apiService.post(url, data);
    return resp;
  } catch (err: any) {
    console.log("error on upsertPrice " + err);
    throw err;
  }
};

export const deletePrice = async (id: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/prices/" + id);
    return resp;
  } catch (err: any) {
    console.log("error on deletePrice " + err);
    throw err;
  }
};
