import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const addJobSketch = async (jobId: string, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.multiPost(
      apiUrl + `/jobs/${jobId}/sketches`,
      data
    );
    console.log("job sketch file added ");
    return resp;
  } catch (err: any) {
    console.log("error on addSketchFile " + err);
    return { error: err.message };
  }
};

export const updateJobSketch = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(
      apiUrl + `/jobs/sketches/${data.id}`,
      data
    );
    console.log("job sketch(s) updated ");
    return resp;
  } catch (err: any) {
    console.log("error on updateSketch " + err);
    return { error: err.message };
  }
};

export const deleteJobSketch = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + `/jobs/sketches/${id}`);
    console.log("job sketch deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deleteSketch " + err);
    return { error: err.message };
  }
};

export const fetchJobSketch = async (sketchId: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + `/jobs/sketches/${sketchId}`);
    console.log("job sketch fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchSketch " + err);
    return { error: err.message };
  }
};
