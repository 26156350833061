import { useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BiLogInCircle, BiLogOutCircle, BiUserCircle } from "react-icons/bi";
import {
  BsBricks,
  BsFillPeopleFill,
  BsGear,
  BsWrenchAdjustable,
} from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoHomeFill } from "react-icons/go";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, logout } from "../auth/auth";
import { IAuthUser } from "../auth/types";
import { wait } from "../common/utils";
import { UserContext } from "../context/UserContext";
import { fetchUser } from "../services/userApiService";
import { isAdmin } from "../common/userUtils";

const RootNavBar: React.FC = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(true);
  const [userShowNav, setUserShowNav] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<IAuthUser>({} as IAuthUser);
  const location = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    setIsLoggedIn(isAuthenticated());

    const removeNavTransitionEndListener = listenToNavTransitionEnd();

    const _showNav = getShowNav();
    setShowNav(_showNav);

    return () => {
      removeNavTransitionEndListener();
    };
  }, []);

  // wait for the page to load before querying the user
  const {
    data: userData,
    isError,
    error,
  } = useQuery({
    queryKey: ["user"],
    queryFn: () => fetchUser(),
    staleTime: Infinity,
    enabled: isLoggedIn,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1048 && userShowNav) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userShowNav]);

  useEffect(() => {
    const { data } = userData || {};
    setUser(data);
    if (isError) {
      console.error("error fetching user info error: ", error.message);
    }
  }, [userData]);

  async function handleLogout() {
    logout();
    queryClient.clear();
    setIsLoggedIn(false);
    await wait(1);
    navigate("/login");
  }

  const navClass = classNames("side-nav", {
    active: showNav,
  });

  const navLabelClass = classNames("side-nav-label", {
    active: showNav,
  });

  const navWideLabelClass = classNames("side-nav-label", {
    "ml-4": showNav,
    active: showNav,
    "d-none": !showNav,
  });

  const navFooterClass = classNames("nav-footer", {
    active: showNav,
  });

  const jobMenuClass = classNames("collapse nav flex-column ml-4", {
    show: showNav,
  });

  function getFirstName() {
    const name = user?.name || "User";
    const firstName = name.split(" ")[0];
    return firstName;
  }

  function goToProfile(event: any): void {
    event.preventDefault();
    navigate("/profile");
  }

  function goToSettingsPage(event: any): void {
    event.preventDefault();
    navigate("/settings");
  }

  function HomeClassName() {
    return location.pathname === "/" || location.pathname == "/home"
      ? "active"
      : "";
  }

  function handleShowNav() {
    localStorage.setItem("showNav", JSON.stringify(!showNav));
    setUserShowNav(!showNav);
    setShowNav(!showNav);
  }

  function getShowNav() {
    const showNav = JSON.parse(localStorage.getItem("showNav") || "false");
    setUserShowNav(showNav);
    return showNav;
  }

  function getActiveClass(pathname: string) {
    return location.pathname === pathname ? "active" : "";
  }

  return (
    <>
      <div>
        <div className="nav-header">
          <GiHamburgerMenu className="ml-1" onClick={handleShowNav} />
        </div>
        <div className={navClass}>
          <nav>
            <ul>
              <li className="p-0">
                <Link to={`/`} className={HomeClassName()}>
                  <div className="nav-item">
                    <span>
                      <GoHomeFill className="nav-item-icon" />
                    </span>
                    <span className={navLabelClass}>Home</span>
                  </div>
                </Link>
              </li>
              <li className="p-0">
                <Link to={`/leads`} className={getActiveClass("/leads")}>
                  <div className="nav-item">
                    <span>
                      <BsFillPeopleFill className="nav-item-icon" />
                    </span>
                    <span className={navLabelClass}>Leads</span>
                  </div>
                </Link>
              </li>
              <li>
                <a
                  href="#jobs-submenu"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle nav-item"
                >
                  <div className="nav-item">
                    <span>
                      <BsBricks className="nav-item-icon" />
                    </span>
                    <span className={navLabelClass}>Jobs</span>
                  </div>
                </a>
                <ul
                  className={jobMenuClass}
                  id="jobs-submenu"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <Link
                      to={`/jobs/progress`}
                      className={getActiveClass("/jobs/progress")}
                      title="Jobs Progress"
                    >
                      P
                      <span
                        className={navLabelClass}
                        style={{ padding: "2px" }}
                      >
                        rogress
                      </span>{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/jobs`}
                      className={getActiveClass("/jobs")}
                      title="Jobs List"
                    >
                      L<span className={navLabelClass}>ist</span>
                    </Link>
                  </li>
                  {isAdmin(user) && (
                    <li>
                      <Link
                        to={`/installer-payments`}
                        className={getActiveClass("/installer-payments")}
                        title="Installer Payments"
                      >
                        I<span className={navLabelClass}>nstaller</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
              <li>
                <Link to={`/repairs`} className={getActiveClass("/repairs")}>
                  <div className="nav-item">
                    <span>
                      <BsWrenchAdjustable className="nav-item-icon" />
                    </span>
                    <span className={navLabelClass}>Repairs</span>
                  </div>
                </Link>
              </li>
              <li>
                <a
                  href="#reports-submenu"
                  data-bs-toggle="collapse"
                  className="nav-link px-0 align-middle nav-item"
                >
                  <div className="nav-item">
                    <span>
                      <HiOutlineDocumentReport className="nav-item-icon" />
                    </span>
                    <span className={navLabelClass}>Reports</span>
                  </div>
                </a>
                <ul
                  className={jobMenuClass}
                  id="reports-submenu"
                  data-bs-parent="#menu"
                >
                  <li className="w-100">
                    <Link
                      to={`/reports/goals`}
                      className={getActiveClass("/reports/goals")}
                      title="Team Goals"
                    >
                      G
                      <span
                        className={navLabelClass}
                        style={{ padding: "2px" }}
                      >
                        oals
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/reports/power-bi`}
                      className={getActiveClass("/reports/power-bi")}
                      title="Power BI"
                    >
                      P<span className={navLabelClass}>ower BI</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className={navFooterClass}>
              <hr className="custom-separator" />
              {!isLoggedIn && (
                <nav>
                  <ul>
                    <li>
                      <Link to={`/login`}>
                        <div className="nav-item">
                          <span>
                            <BiLogInCircle className="nav-item-icon" />
                          </span>
                          <span className={navLabelClass}>Login</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </nav>
              )}
              {isLoggedIn && (
                <div className="dropdown navbar-user-dropdown pb-4 ml-5">
                  <a
                    href="#"
                    className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                    id="dropdownUser1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        user?.profile_image
                          ? user.profile_image
                          : "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                      }
                      alt="user-img"
                      width="30"
                      height="30"
                      className="rounded-circle"
                    />
                    <span className={navWideLabelClass}>{getFirstName()}</span>
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-dark text-small shadow"
                    aria-labelledby="dropdownUser1"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={goToSettingsPage}
                      >
                        <BsGear className="ml-1" />
                        Settings
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={goToProfile}
                      >
                        <BiUserCircle className="ml-1" />
                        Profile
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={handleLogout}
                      >
                        <BiLogOutCircle className="ml-1" />
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>
      <div id="detail">
        <UserContext.Provider value={[user, setUser]}>
          <Outlet />
        </UserContext.Provider>
      </div>
    </>
  );
};

export default RootNavBar;

// function listenToNavTransitionEnd() {
//   const sideNavLabels = document.querySelectorAll(".side-nav-label");
//   const first = sideNavLabels[0];
//   first.addEventListener("transitionend", () => {
//     const styles = getComputedStyle(first);
//     if (styles.opacity == "0") {
//       sideNavLabels.forEach((elem) => {
//         elem.classList.add("d-none");
//       });
//     } else {
//       sideNavLabels.forEach((elem) => {
//         elem.classList.remove("d-none");
//       });
//     }
//   });
// }

function listenToNavTransitionEnd() {
  const sideNavLabels = document.querySelectorAll(".side-nav-label");
  const first = sideNavLabels[0];

  const handleTransitionEnd = () => {
    const styles = getComputedStyle(first);
    if (styles.opacity == "0") {
      sideNavLabels.forEach((elem) => {
        elem.classList.add("d-none");
      });
    } else {
      sideNavLabels.forEach((elem) => {
        elem.classList.remove("d-none");
      });
    }
  };

  first.addEventListener("transitionend", handleTransitionEnd);

  // Return a cleanup function that removes the event listener
  return () => {
    first.removeEventListener("transitionend", handleTransitionEnd);
  };
}
