import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { createLead } from "../../../services/leadApiService";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  formatErrorMessage,
  isValidEmail,
  isValidPhoneNumber,
} from "../../../common/utils";
import { QueryClient, useMutation } from "@tanstack/react-query";

interface Props {
  show: boolean;
  onHide: () => void;
}

const CreateLeadDialog: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState<any>({});
  const { register, handleSubmit, reset, formState, getValues } = useForm({
    defaultValues: {
      customer_name: "",
      phone: "",
      email: "",
    },
  });
  let dialogInstance = useRef<DialogComponent>(null);

  const buttons = [
    {
      // Click the footer buttons to hide the Dialog
      click: () => {
        if (isValidForm()) {
          dialogClose();
          createNewLead();
        }
      },
      // Accessing button component properties by buttonModel property
      buttonModel: {
        //Enables the primary button
        isPrimary: true,
        content: "Create",
        type: "submit",
      },
    },
  ];

  useEffect(() => {
    reset();
    setShowModal(props.show);
  }, [props.show]);

  const { mutateAsync } = useMutation({
    mutationFn: createLead,
  });
  const queryClient = new QueryClient();

  const dialogClose = (): void => {
    props.onHide();
  };

  const isValidForm = () => {
    const data = getValues();
    const errors: any = {};
    if (data.customer_name.length < 2) {
      errors["customer_name"] = "Customer name must be at least 2 characters";
    }
    if (!isEmpty(data.email)) {
      if (!isValidEmail(data.email)) {
        errors["email"] = "invalid email address";
      }
    }
    if (!isEmpty(data.phone)) {
      if (!isValidPhoneNumber(data.phone)) {
        errors["phone"] = "invalid phone number";
      }
    }
    setState({ ...state, errors: errors });
    return isEmpty(errors);
  };

  const createNewLead = async () => {
    const data = getValues();
    console.log(
      "createNewLead: ",
      data,
      formState.isDirty,
      isEmpty(state.errors)
    );
    if (!isEmpty(state.errors)) {
      return;
    }

    try {
      const response = await mutateAsync(data);
      queryClient.invalidateQueries({ queryKey: ["leads"] });
      navigate(`/leads/${response.data.id}`);
    } catch (err: any) {
      alert("Error creating lead: " + formatErrorMessage(err.message));
    }
  };

  return (
    <div className="control-section modal-dialog-target">
      <div id="target" className="col-lg-8">
        <DialogComponent
          id="modalDialog"
          showCloseIcon={true}
          isModal={true}
          buttons={buttons}
          header="Create New Lead"
          width="600px"
          ref={dialogInstance}
          target="#target"
          visible={showModal}
          close={dialogClose}
        >
          <div>
            <div className="row">
              <div className="form-group px-4">
                <label htmlFor="customer" className="form-label">
                  Customer Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Joe Doe"
                  autoComplete="off"
                  spellCheck="false"
                  {...register("customer_name")}
                />
                {state.errors?.customer_name && (
                  <span className="text-danger">
                    {state.errors?.customer_name}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group pt-3 px-4">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="801-555-5555"
                  autoComplete="off"
                  spellCheck="false"
                  {...register("phone")}
                />
                {state.errors?.phone && (
                  <span className="text-danger">{state.errors.phone}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group p-3 px-4">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="name@mail.com"
                  autoComplete="off"
                  spellCheck="false"
                  {...register("email")}
                />
                {state.errors?.email && (
                  <span className="text-danger">{state.errors.email}</span>
                )}
              </div>
            </div>
          </div>
        </DialogComponent>
      </div>
    </div>
  );
};

export default CreateLeadDialog;
