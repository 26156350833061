import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/UserContext";
import { fetchProgressGroups } from "../../../services/jobApiService";
import GoogleMapsView from "./GoogleMapsView";
import { convertHashToArray, findLeadsGeocode } from "./JobProgressService";
import dollar from "../../../assets/images/maps/dollar.png";
import waiting from "../../../assets/images/maps/waiting.png";
import pickup from "../../../assets/images/maps/pickup.png";
import star from "../../../assets/images/maps/star-3.png";
import flag_export from "../../../assets/images/maps/flag-export.png";
import just_sold from "../../../assets/images/maps/just_sold.png";
import pin_red from "../../../assets/images/maps/pin_red.png";
import { IJobProgress } from "./types";

import {
  faMapPin,
  faHome,
  faMapMarked,
} from "@fortawesome/free-solid-svg-icons";

const JobProgressMap: React.FC = () => {
  const navigate = useNavigate();
  const [markers, setMarkers] = useState<any[]>();
  const [user] = useUserContext();

  const apiKey = getGoogleMapsApiKey();

  const { data, isError, error } = useQuery({
    queryKey: ["progressGroups"],
    queryFn: fetchProgressGroups,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isError) {
      console.error("Error occurred:", error.message);
      return;
    }
    processData(data);
  }, [data, error]);

  async function processData(resp: any) {
    const flattedList = convertHashToArray(resp);
    console.log("flattedList", flattedList);
    const updatedList = await findLeadsGeocode(flattedList, apiKey);
    console.log("updatedList", updatedList);
    createMarkers(updatedList);
  }

  function getGoogleMapsApiKey() {
    const integration = user?.integrations?.find(
      (d: any) => d.name === "google-maps"
    );
    return integration?.settings;
  }

  function createMarkers(data: IJobProgress[]) {
    const markersData = data.map((item) => {
      const path: any = faMapPin.icon[4];
      return {
        position: {
          lat: item.lead.geocode.lat,
          lng: item.lead.geocode.lng,
        },
        title: item.lead.customer_name + "\n" + item.labels[0]?.name,
        lead: item.lead,
        status: item.labels[0]?.name,
        icon: {
          url: getMarkerIcon(item),
        },
      };
    });
    setMarkers(markersData);
  }

  function getMarkerIcon(item: IJobProgress): any {
    switch (item.labels[0]?.name) {
      case "Just Sold":
        return just_sold;
      case "On Hold":
        return waiting;
      case "To Start":
        return flag_export;
      case "In Progress":
        return star;
      case "Clean Up":
        return pickup;
      case "Waiting for Final Payment":
        return dollar;
      default:
        return pin_red;
    }
  }

  return (
    <div className="pt-3" style={{}}>
      <div className="d-flex justify-content-between px-3">
        <div className="page-title">Jobs Progress Map</div>
        <div>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => navigate("/jobs/progress")}
          >
            Grid View
          </button>
        </div>
      </div>
      <div className="bg-dark">
        {apiKey && <GoogleMapsView markers={markers} apiKey={apiKey} />}
      </div>
    </div>
  );
};

export default JobProgressMap;
