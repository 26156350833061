import { Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import { generateGoalsData, getMonths } from "./goalsService";

interface IProps {
  jobsSold: any;
  goals: any;
}

const chartOptions: any = {
  plugins: {
    title: {
      display: true,
      text: "Jobs Sold",
      align: "start",
      font: {
        size: 18,
        weight: "bold",
      },
    },
    subtitle: {
      display: true,
      align: "start",
      text: "Dollar Amounts",
      font: {
        size: 12,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const chartInit = {
  labels: getMonths(),
  datasets: [
    {
      label: "lastYear",
      data: [] as any,
      type: "bar" as const,
      backgroundColor: "rgb(153, 102, 255)",
      // borderColor: "#3d7fff55",
      borderWidth: 1,
      borderRadius: 3,
    },
    {
      label: "thisYear",
      data: [] as any,
      type: "bar" as const,
      backgroundColor: "rgb(255, 99, 132)",
      // borderColor: "rgba(75, 192, 192, 0.8)",
      borderWidth: 1,
    },
    {
      label: "Goals",
      data: [] as any,
      type: "bar" as const,
      // backgroundColor: "rgb(153, 102, 255)", // a shade of purple
      // backgroundColor: "rgb(255, 159, 64)", // a shade of orange
      // backgroundColor: "#EB9486", // a shade of red
      backgroundColor: "rgb(255, 205, 86)", // a shade of red
    },
  ],
};

const GoalsChart: React.FC<IProps> = (props) => {
  const { jobsSold, goals } = props;
  const chartRef: any = useRef(null);
  const [chartData, setChartData] = useState(chartInit);

  useEffect(() => {
    processChartData(jobsSold, goals);
  }, [jobsSold, goals]);

  function processChartData(jobsSold: any, goals: any) {
    const _chartData = { ...chartData };

    const { lastYearSales, thisYearSales, salesGoals } = generateGoalsData(
      jobsSold,
      goals
    );

    _chartData.datasets[0].label = lastYearSales.label;
    _chartData.datasets[0].data = lastYearSales.data;

    _chartData.datasets[1].label = thisYearSales.label;
    _chartData.datasets[1].data = thisYearSales.data;

    _chartData.datasets[2].data = salesGoals.data;
    setChartData(_chartData);
  }

  return (
    <>
      <div className="row m-3 mt-5">
        <Paper elevation={3} className="dashboard-card-medium">
          <div className="dashboard-card-body">
            <Chart
              ref={chartRef}
              key={Math.random()}
              data={chartData}
              type="bar"
              options={chartOptions}
            />
          </div>
        </Paper>
      </div>
    </>
  );
};

export default GoalsChart;
