import React, { useEffect, useState } from "react";
import { VscSettings } from "react-icons/vsc";
import { ToastContainer } from "react-toastify";
import { fetchGoals } from "../../services/reportApiService";
import GoalsChart from "./GoalsChart";
import GoalsDetails from "./GoalsDetails";
import GoalsEditDialog from "./GoalsEditDialog";
import { IGoals } from "./types";
import GoalsTable from "./GoalsTable";
import { useQuery } from "@tanstack/react-query";

const Goals: React.FC = () => {
  const [goals, setGoals] = useState<IGoals>({} as IGoals);
  const [jobsSold, setJobsSold] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [state, setState] = useState<any>({});

  const { data, isError, error } = useQuery({
    queryKey: ["goals"],
    queryFn: fetchGoals,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data?.data) {
      const { goals, jobs_sold } = data.data;
      setGoals(goals || {});
      setJobsSold(jobs_sold || []);
    }
    if (isError) {
      handleOnError(error);
    }
  }, [data, error]);

  function handleOnError(error: any) {
    setState({ error: error.message });
  }

  function handleEditClick(event: any): void {
    setShowEditDialog(true);
  }

  function handleOnSaved(goals: IGoals) {
    setGoals(goals);
  }

  return (
    <>
      <GoalsEditDialog
        goals={goals}
        show={showEditDialog}
        onHide={() => setShowEditDialog(false)}
        onSaved={handleOnSaved}
      />
      <ToastContainer />
      <div className="p-3 mr-5">
        <div className="row">
          <div className="page-title col">Team Goal</div>
          <div className="col d-flex justify-content-end mr-5">
            <button className="btn btn-primary" onClick={handleEditClick}>
              <VscSettings className="mr-2" />
              Edit
            </button>
          </div>
        </div>
        {state.error && (
          <div className="row m-3">
            <div className="alert alert-danger">
              <h4 className="alert-heading">Error!</h4>
              <p>{state.error}</p>
            </div>
          </div>
        )}
        <GoalsDetails jobsSold={jobsSold} goals={goals} />
        <GoalsChart jobsSold={jobsSold} goals={goals} />
        <GoalsTable jobsSold={jobsSold} goals={goals} />
      </div>
    </>
  );
};

export default Goals;
