import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const addJobLineItem = async (data: any) => {
  const apiService = new ApiService();
  const resp = await apiService.post(apiUrl + "/lineitems", data);
  console.log("job line item added ");
  return resp;
};

export const addJobLineItems = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(apiUrl + "/lineitems/bulk", data);
    console.log("job line items added ");
    return resp;
  } catch (err: any) {
    console.log("error on addJobLineItems " + err);
    throw err;
  }
};

export const getJobLineItems = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + "/lineitems/" + id);
    console.log("job line item added ");
    return resp;
  } catch (err: any) {
    console.log("error on getJobLineItems " + err);
    throw err;
  }
};

export const deleteJobLineItem = async (id: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/lineitems/" + id);
    console.log("job line item deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deleteJobLineItem " + err);
    throw err;
  }
};

export const deleteJobLineItems = async (data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/lineitems", data);
    console.log("job line items deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deleteJobLineItems " + err);
    throw err;
  }
};

export const updateJobLineItem = async (input: any) => {
  const { id, data } = input;
  const apiService = new ApiService();
  const resp = await apiService.put(apiUrl + "/lineitems/" + id, data);
  console.log("job line item updated ");
  return resp;
};

export const updateJobLineItems = async (data: any) => {
  const apiService = new ApiService();
  const resp = await apiService.put(apiUrl + "/lineitems", data);
  console.log("job line items updated ");
  return resp;
};
