import { Tooltip } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { generatePassword } from "../../../common/utils";
import { phoneRegex } from "../../../config/constants";
import { showToaster } from "../../../services/toastService";
import { createUser } from "../../../services/userApiService";
import { IRole, IUser } from "./types";

interface IProps {
  show: boolean;
  onHide: (e?: any) => void;
  updateUsersList: (user: IUser) => void;
}

const UserCreateDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [roles, setRoles] = useState<IRole[]>([]);

  const { mutateAsync } = useMutation({
    mutationFn: createUser,
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    setShowModal(props.show);
    const result = queryClient.getQueryData<any>(["roles"]);
    const roles = result?.data || [];
    setRoles(result?.data || []);
    reset({
      role_id: roles?.find((role: IRole) => role.name === "User")?.id,
    });
  }, [props.show]);

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { isDirty, dirtyFields, errors },
    getValues,
    setValue,
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleCloseDialog = () => {
    setShowModal(false);
    props.onHide();
  };

  const onSubmit = (data: any) => {
    console.log("onSubmit", data);
  };

  const handleCreateUser = async () => {
    await handleSubmit(onSubmit)();
    if (!isEmpty(errors) || !isDirty) return;
    const data = getValues();

    const resp = await mutateAsync(data);
    handleCloseDialog();

    if (resp.data) {
      props.updateUsersList(resp.data);
      showToaster("User created: " + resp.data.name);
      if (data.password) {
        props.updateUsersList(resp.data);
        navigator.clipboard.writeText(data.password);
        showToaster("Password copied to clipboard");
      }
    } else {
      showToaster("Error create user " + JSON.stringify(resp.error), "error");
    }
  };

  function setPassword(): void {
    const password = generatePassword();
    setValue("password", password);
    setValue("password_confirmation", password);
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={handleCloseDialog}
      dialogClassName="modal-wide"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row p-3 g-3 align-items-center">
          <div className="mb-3 row">
            <label
              htmlFor="name"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Joe Doe"
                {...register("name", { required: true, minLength: 2 })}
              />
              {errors.name && errors.name.type === "required" && (
                <span className="text-danger">This is required</span>
              )}
              {errors.name && errors.name.type === "minLength" && (
                <span className="text-danger">Min length 2</span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="display_name"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Display Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Joe"
                {...register("display_name")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="email"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Email
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("email", {
                  required: true,
                  pattern: /\S+@\S+\.\S+/,
                })}
                placeholder="name@email.com"
              />
              {errors.email && errors.email.type === "required" && (
                <span className="text-danger">This is required</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span className="text-danger">Invalid email address</span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="phone"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Phone
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("phone", {
                  required: false,
                  pattern: phoneRegex,
                })}
                placeholder="000-000-0000"
              />
              {errors.phone && errors.phone.type === "pattern" && (
                <span className="text-danger">Invalid phone number</span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="password"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Password
            </label>
            <div className="col-sm-8">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={null}
                  {...register("password", {
                    required: true,
                    minLength: 6,
                    pattern: /^(?=.*[A-Z])(?=.*\d|.*[\W_])(?!.*\s).{6,}$/,
                  })}
                  autoComplete="new-password"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={setPassword}
                >
                  Generate
                </button>
              </div>
              {errors.password && errors.password.type === "required" && (
                <span className="text-danger">This is required</span>
              )}
              {errors.password && errors.password.type === "minLength" && (
                <span className="text-danger">Min length 6</span>
              )}
              {errors.password && errors.password.type === "pattern" && (
                <span className="text-danger">Invalid password</span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="password_confirmation"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Confirm Password
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                defaultValue={null}
                autoComplete="off"
                {...register("password_confirmation", {
                  required: true,
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
              />
              {errors.password_confirmation && (
                <span className="text-danger">
                  Password confirmation does not match
                </span>
              )}
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="role"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Permission Level
            </label>
            <div className="col-sm-4">
              <select className="form-select" {...register("role_id")}>
                {roles &&
                  roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col">
              <Tooltip title="user:sales reps, super user:supervisors">
                <a href="#" className="btn btn-sm btn-outline-secondary">
                  ?
                </a>
              </Tooltip>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="position"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Role
            </label>
            <div className="col-sm-4">
              <select className="form-select" {...register("position")}>
                <option value="none">None</option>
                <option value="office">Office</option>
                <option value="sales-rep">Sales Rep</option>
                <option value="installer">Installer</option>
              </select>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="active"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Notes
            </label>
            <div className="col-sm-8">
              <textarea className="form-control" {...register("notes")} />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleCreateUser}
          disabled={!isDirty}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserCreateDialog;
