import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatErrorMessage } from "../../../common/utils";
import { deleteLeadLabel } from "../../../services/labelsApiService";
import { ILabel } from "../types";

interface IProps {
  leadId?: string;
  labels: ILabel[];
}

const LeadPills: React.FC<IProps> = (props) => {
  const { labels } = props;
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: deleteLeadLabel,
  });

  async function deleteLabel(id: any) {
    console.log("deleteLabel " + id);
    try {
      await mutateAsync(id);
      queryClient.invalidateQueries({
        queryKey: ["lead"],
      });
    } catch (err: any) {
      console.log("error on deleteLabel " + formatErrorMessage(err.message));
    }
  }

  return (
    <div className="d-flex flex-row-reverse">
      {labels?.map((label: ILabel) => (
        <button
          key={label.pivot.id}
          type="button"
          className="btn btn-info btn-sm mr-2"
          onClick={() => deleteLabel(label.pivot.id)}
        >
          {label.name}
          <span className="bi bi-x-circle ml-2"></span>
        </button>
      ))}
    </div>
  );
};

export default LeadPills;
