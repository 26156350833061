import { Marker } from "@react-google-maps/api";
import { AdvancedMarker, useMap } from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import geneva_rock_img from "../../../assets/images/maps/geneva_rock.png";
import pit_img from "../../../assets/images/maps/geneva_rock1.png";
import home_depot_img from "../../../assets/images/maps/home_depot.png";
import landfill_img from "../../../assets/images/maps/landfill.png";
import shell_img from "../../../assets/images/maps/shell.png";
import staker_parson_img from "../../../assets/images/maps/staker_parson.png";
import { fetchNearByServices } from "../../../services/mapsApiService";
import { IMarker } from "./types";

interface IProps {
  markers: IMarker[];
  apiKey: string;
}

const ServicesMarkers: React.FC<IProps> = (props) => {
  const map = useMap();
  const infoWindow: any = new google.maps.InfoWindow();
  let markers: { [key: number]: Marker } = {};
  const [services, setServices] = useState<any[]>([]);

  useEffect(() => {
    console.log("points", props.markers);
    if (props.markers) {
      getServicesLocations(props.markers);
    }
  }, [props.markers]);

  async function getServicesLocations(markers: IMarker[] = []) {
    const services = [];
    for (const marker of markers) {
      if (!marker?.position?.lat) continue;
      const resp = await fetchNearByServices({
        location: `${marker.position.lat},${marker.position.lng}`,
        radius: 10000,
      });

      if (!resp?.data) continue;

      for (const service of resp.data) {
        services.push({
          position: {
            lat: service.location.lat,
            lng: service.location.lng,
          },
          title: service.name,
          address: service.address,
          place_id: service.place_id,
          service_name: service.name,
        });
      }
    }
    console.log("formatted services", services);
    setServices(getUniquePlaces(services));
  }

  function getUniquePlaces(services: any) {
    const uniquePlaceIds = new Set();
    const uniqueServices = [];

    for (const service of services) {
      if (!uniquePlaceIds.has(service.place_id)) {
        uniquePlaceIds.add(service.place_id);
        uniqueServices.push(service);
      }
    }

    return uniqueServices;
  }

  function handleMarkerClick(marker: any, index: number) {
    const directions = `https://www.google.com/maps/dir/Current+Location/${marker.position.lat},${marker.position.lng}`;
    infoWindow.setHeaderContent(marker.title);
    infoWindow.setContent(
      `<div style="font-weight:normal;">${marker.address}</div>
        <a style="font-size:small" target="_blank" href="${directions}">Get Directions</a>
        <br />`
    );
    const anchor = markers[index];
    infoWindow.open(map, anchor);
  }

  const setMarkerRef = (marker: Marker | null, key: number) => {
    if ((marker && markers[key]) || (!marker && !markers[key])) return;

    if (marker) {
      markers[key] = marker;
    } else {
      delete markers[key];
    }
  };

  function getMarkerImage(service_name: string): string {
    const beachFlagImg =
      "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

    if (!service_name) {
      return beachFlagImg;
    }
    service_name = service_name.toLowerCase();
    if (service_name.includes("shell")) {
      return shell_img;
    }
    if (service_name.includes("geneva")) {
      return geneva_rock_img;
    }
    if (service_name.includes("staker")) {
      return staker_parson_img;
    }
    if (service_name.includes("home depot")) {
      return home_depot_img;
    }
    if (service_name.includes("landfill")) {
      return landfill_img;
    }
    if (service_name.includes("christensen")) {
      return pit_img;
    }
    return beachFlagImg;
  }

  return (
    <>
      {services?.map((marker, index) => (
        <AdvancedMarker
          key={marker?.title + index}
          position={marker?.position}
          title={marker?.title}
          onClick={(e) => {
            handleMarkerClick(marker, index);
          }}
          ref={(m: any) => setMarkerRef(m, index)}
        >
          <img src={getMarkerImage(marker.service_name)} />
        </AdvancedMarker>
      ))}
    </>
  );
};

export default ServicesMarkers;
