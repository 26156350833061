import { useQuery } from "@tanstack/react-query";
import currency from "currency.js";
import React, { useEffect, useState } from "react";
import { AiOutlineInteraction } from "react-icons/ai";
import { BsFiletypePdf, BsWrenchAdjustable } from "react-icons/bs";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { TbEdit, TbFileExport } from "react-icons/tb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../common/dateUtils";
import {
  adaptiveCurrencyFormat,
  currencyFormat,
  formatErrorMessage,
} from "../../common/utils";
import { fetchJob } from "../../services/jobApiService";
import { createInvoice } from "../../services/quickbooksApiService";
import { showToaster } from "../../services/toastService";
import JobCustomerInfo from "./sidePane/JobCustomerInfo";
import JobPills from "./sidePane/JobPills";
import { IFeature, IJob } from "./types";
import ProgressSpinner from "../common/ProgressSpinner";

const JobEdit: React.FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [job, setJob] = useState<IJob>({} as IJob);
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading, error, isError } = useQuery({
    queryKey: ["job", { jobId }],
    queryFn: () => fetchJob(jobId),
    staleTime: Infinity,
    retry: 1,
  });

  useEffect(() => {
    if (data) {
      setJob(data.data);
    }
    if (isError) {
      setJob(null);
      console.log("error useEffect", error);
    }
  }, [data, isError]);

  function handlePaversClick() {
    navigate(`/jobs/${jobId}/pavers`);
  }

  function getTotalSum() {
    const total = job.line_items?.reduce(
      (a: any, b: any) => a + currency(b.total).value,
      0
    );
    return currencyFormat(total, "$");
  }

  const handleEditClick = () => {
    navigate(`/jobs/${jobId}/edit`);
  };

  function viewPDF(event: any): void {
    event.preventDefault();
    navigate(`/jobs/${job.uid}/pdf`);
  }

  async function sendToQuickBooks(event: any) {
    event.preventDefault();
    try {
      const resp = await createInvoice(job.id);
      console.log(resp.data);
      showToaster("Invoice created successfully id:" + resp.data.Id);
    } catch (err: any) {
      console.log("error on sendToQuickBooks " + err);
      showToaster("Error: " + formatErrorMessage(err.message), "error");
    }
  }

  function showInstallerPayment(event: any): void {
    event.preventDefault();
    navigate(`/jobs/${job.id}/installer-payments`);
  }

  function navigateToRepairs(event: any): void {
    event.preventDefault();
    navigate(`/jobs/${job.id}/repairs`);
  }

  function handleJobPillsDelete(id: any): void {
    const labels = job.labels.filter((label: any) => label.pivot.id !== id);
    setJob({
      ...job,
      labels,
    });
  }

  function handleGoBack(event: any) {
    event.preventDefault();
    if (location.state) {
      navigate("/jobs", { state: location.state });
    } else {
      navigate(-1);
    }
  }

  if (isError) {
    return (
      <div className="row m-3">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>Job not found.</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <ProgressSpinner show={isLoading} />
      <div className="container">
        <div className="pt-3 pb-5">
          <div className="d-flex flex-row mb-3">
            <div className="mr-2">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={handleGoBack}
              >
                <i className="bi bi-arrow-left"></i> Back
              </button>
            </div>
            <div className="mb-2 mr-2">
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <AiOutlineInteraction className="mr-2 mb-1" />
                  Actions
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={showInstallerPayment}
                    >
                      <LiaFileInvoiceDollarSolid className="mr-2 mb-1" />
                      Installer Payments
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={navigateToRepairs}
                    >
                      <BsWrenchAdjustable className="mr-2 mb-1" />
                      Repairs
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={sendToQuickBooks}
                    >
                      <TbFileExport className="mr-2 mb-1" />
                      Send to QuickBooks
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={viewPDF}>
                      <BsFiletypePdf className="mr-2 mb-1" />
                      View PDF
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mb-2 mr-2">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={handlePaversClick}
                style={{ minWidth: "102px" }}
              >
                <AiOutlineInteraction className="mr-2 mb-1" />
                <span className="d-none d-xl-inline">Paver </span>Orders
              </button>
            </div>
            <div className="mr-2">
              <button
                className="btn btn-primary"
                style={{ minWidth: "80px" }}
                onClick={handleEditClick}
                disabled={job.read_only}
              >
                <TbEdit className="mr-2 mb-1" />
                Edit
              </button>
            </div>
            <div className="col">
              <div className="d-flex justify-content-end">
                <JobPills
                  jobId={jobId}
                  labels={job.labels}
                  onDeleted={handleJobPillsDelete}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <JobCustomerInfo lead={job.lead} />
            </div>
            <div className="col">
              <div className="">
                <div className="text-strong">Title: {job.name}</div>
                <div className="text-strong">
                  {" "}
                  #: {job.code ? job.code : ""}
                </div>
                <div className="text-strong">Total: {getTotalSum()}</div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row mb-3 px-3">
            <div className="col-md-3">
              <div style={{ fontSize: "10pt" }}>
                <div>
                  <span className="text-strong">Job Type: </span>
                  {job.job_type}
                </div>
                <div>
                  <span className="text-strong">Customer Type: </span>:{" "}
                  {job.customer_type}
                </div>
                <div>
                  <span className="text-strong">Contractor Name: </span>{" "}
                  {job.contractor}
                </div>
                <div>
                  <span className="text-strong">Property Type: </span>{" "}
                  {job.property_type}
                </div>
                <div>
                  <span className="text-strong">Date Sold: </span>{" "}
                  {formatDate(job.date_sold)}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div style={{ fontSize: "10pt" }}>
                <div>
                  <span className="text-strong">Size: </span>
                  {adaptiveCurrencyFormat(job.size)}
                </div>
                <div>
                  <span className="text-strong">SQ.FT. Price: </span>:{" "}
                  {currencyFormat(job.sqft_price)}
                </div>
                <div>
                  <span className="text-strong">Proposal Amount: </span>{" "}
                  {currencyFormat(job.proposal_amount)}
                </div>
                <div>
                  <span className="text-strong">Amount Invoiced: </span>{" "}
                  {job.invoiced_amount}
                </div>
                <div>
                  <span className="text-strong">Start Date: </span>{" "}
                  {formatDate(job.start_date)}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div style={{ fontSize: "10pt" }}>
                <div>
                  <span className="text-strong">Crew: </span>
                  {job.crew}
                </div>
                <div>
                  <span className="text-strong">Down payment: </span>:{" "}
                  {job.downpayment}
                </div>
                <div>
                  <span className="text-strong">Features: </span>{" "}
                  {job.features
                    ?.filter((f: IFeature) => f.pivot.active)
                    .map((feature: any) => feature.name)
                    .join(", ")}
                </div>
                <div>
                  <span className="text-strong">Signature Date: </span>{" "}
                  {formatDate(job.signed_at)}
                </div>
                <div>
                  <span className="text-strong">Finish Date: </span>{" "}
                  {formatDate(job.finish_date)}
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="line-items-panel">
              {/* <?php $lineItemsAmountSum = 0; ?> */}
              <label className="item-test">Line Items:</label>
              <div className="table-responsive">
                <table className="table line-item-table">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Size</th>
                      <th>Unit</th>
                      <th>Unit Price</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {job.line_items?.map((lineItem: any) => (
                      <tr key={lineItem.id} className="line-item-row">
                        <td className="p-3" style={{ maxWidth: "340px" }}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: lineItem.description?.replace(
                                /\n/g,
                                "<br>"
                              ),
                            }}
                          />
                        </td>
                        <td>{adaptiveCurrencyFormat(lineItem.quantity)}</td>
                        <td>{lineItem.unit}</td>
                        <td className="text-end">
                          {currencyFormat(lineItem.rate)}
                        </td>
                        <td className="text-end" style={{ textAlign: "right" }}>
                          <span className="input-group-text bg-secondary-subtle">
                            {currencyFormat(lineItem.total, "$")}
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr className="line-item-row">
                      <td className="p-3"></td>
                      <td>Total:</td>
                      <td></td>
                      <td></td>
                      <td>
                        <span className="input-group-text bg-secondary text-white">
                          {getTotalSum()}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* TinyMCE text editor */}
          <div className="row mb-3">
            <div className="col-lg-10">
              <label htmlFor="notes" className="form-label">
                Proposal Note:
              </label>
              <div className="bg-light p-3 card" style={{ minHeight: "80px" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: job?.proposal_note || job?.proposal?.text,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-6 mb-3">
              <label htmlFor="notes" className="form-label">
                Job Notes:
              </label>
              <textarea
                className="form-control"
                rows={5}
                value={job.note?.note}
              ></textarea>
            </div>
            <div className="col-lg-6 mb-3">
              <label htmlFor="notes" className="form-label">
                Installer Notes:
              </label>
              <textarea
                className="form-control"
                rows={5}
                value={job.installer_note?.note}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobEdit;
