import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  addRepairImages,
  deleteRepairImage,
  fetchRepair,
  fetchRepairImage,
  updateRepair,
} from "../../services/repairApiService";
import ConfirmDialog from "../common/ConfirmDialog";
import ProgressSpinner from "../common/ProgressSpinner";
import { IRepair } from "./types";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import classNames from "classnames";
import { AiOutlineSave } from "react-icons/ai";
import { LiaUndoSolid } from "react-icons/lia";
import ImageViewer from "../common/ImageViewer";
import JobRepairBreadCrumbs from "./JobRepairBreadCrumbs";
import RepairImageList from "./RepairImageList";
import { formatErrorMessage } from "../../common/utils";

const RepairDetails: React.FC = () => {
  const repairId = useParams().id || "0";
  const navigate = useNavigate();
  const [repair, setRepair] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<any>({});
  const [showImageViewer, setShowImageViewer] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const location = useLocation();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, dirtyFields, errors },
  } = useForm<IRepair>({
    defaultValues: async () => await getDefaultValues(),
  });

  async function getDefaultValues() {
    setLoading(true);
    const resp = await fetchRepair(repairId);
    setLoading(false);
    if (resp.error) {
      setState({ ...state, notFound: true });
      return null;
    }
    const processed = processLeadData(resp.data);
    return processed;
  }

  const calledAtReg = register("called_at");
  const toStartAtReg = register("tostart_at");
  const completedAtReg = register("completed_at");

  function processLeadData(data: any): IRepair {
    setRepair(data);
    return data;
  }

  async function handleUpdateRepair(data: any) {
    setLoading(true);
    const resp = await updateRepair(repairId, data);
    setLoading(false);
    if (resp.data) {
      const processed = processLeadData(resp.data);
      reset(processed);
      toast.success("Repair updated successfully.");
    } else {
      toast.error("Failed to update repair. " + formatErrorMessage(resp.error));
    }
  }

  const fixedTopBarClass = classNames("fixed-top-bar", {
    "d-none": !isDirty,
  });

  function handleDiscard(): void {
    reset();
  }

  function getAddress() {
    const lead = repair?.job?.lead;
    if (!lead) {
      return "";
    }
    return `${lead.street}, ${lead.city}, ${lead.state} ${lead.zip}`;
  }

  async function handleDeleteImage(id: any) {
    console.log("Delete image", id);
    if (!id) return;
    setLoading(true);
    const resp = await deleteRepairImage(repairId, id);
    setLoading(false);

    if (resp.status == "success") {
      const images = repair.images.filter((img: any) => img.filename !== id);
      setRepair({ ...repair, images });
    } else {
      console.log(resp.error);
    }
  }

  async function handlePreviewImage(filename: any) {
    console.log("Preview image", filename);
    setLoading(true);
    const resp = await fetchRepairImage(filename);
    setLoading(false);
    setSelectedImage(resp);
    setShowImageViewer(true);
  }

  async function handleAddImages(event: any) {
    console.log("Add image", event.target.files);

    const files = event.target.files;
    if (!files) {
      return;
    }

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i], files[i].name);
      console.log(files[i].name);
    }

    setLoading(true);

    const resp = await addRepairImages(repairId, formData);
    if (resp.data) {
      setRepair({ ...repair, images: resp.data });
    } else {
      console.log(resp.error);
      toast.error("Failed to upload images.");
    }

    setLoading(false);
  }

  function handleViewPDF(): void {
    navigate(`/repairs/${repairId}/pdf`);
  }

  function handleGoBack(event: any) {
    event.preventDefault();
    navigate(`/repairs`, { state: location.state });
  }

  return (
    <div className="my-3 mx-3 mx-lg-5">
      <ToastContainer />
      <ProgressSpinner show={loading} />
      <ConfirmDialog
        show={state.showConfirmDialog}
        message={`Are you sure you want to delete this repair?`}
        onHide={() => setState({ ...state, showConfirmDialog: false })}
        handleDeleteClick={() => {
          /* handleDelete() */
        }}
        primaryButtonText="Yes"
        primaryButtonVariant="danger"
      />
      <ImageViewer
        show={showImageViewer}
        onClose={() => setShowImageViewer(false)}
        src={selectedImage}
      />
      <div className={fixedTopBarClass}>
        <button
          className="btn btn-primary"
          onClick={handleSubmit(handleUpdateRepair)}
        >
          <AiOutlineSave className="mr-2 mb-1" />
          Save
        </button>
        <button className="btn btn-secondary ml-3" onClick={handleDiscard}>
          <LiaUndoSolid className="mr-2 mb-1" />
          Discard
        </button>
      </div>
      {!state.notFound ? (
        <div className="panel-group py-3 px-5">
          <JobRepairBreadCrumbs job={repair?.job} />
          <div className="row mb-3">
            <div className="col-sm-10">
              <div className="d-flex">
                {location.state && (
                  <div className="mr-2">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleGoBack}
                    >
                      <i className="bi bi-arrow-left"></i> Back
                    </button>
                  </div>
                )}
                <div className="page-title-narrow">Lead Details</div>
              </div>
              <div>
                <span className="text-strong">Job: </span>
                <Link
                  to={`/jobs/${repair?.job?.id}`}
                  className="text-decoration-none ml-2"
                >
                  {repair?.job?.code}
                </Link>
              </div>
              <div>
                <span className="text-strong">Customer: </span>
                {repair?.job?.lead?.customer_name}
              </div>
              <div>
                <span className="text-strong">Address: </span> {getAddress()}
              </div>
            </div>
            <div className="col">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-outline-secondary"
                  onClick={handleViewPDF}
                >
                  View PDF
                </button>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 mb-3">
              <div className="form-group phone-group">
                <label htmlFor="phone" className="form-label">
                  Contact
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("contact")}
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group phone-group">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("phone")}
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group phone-group">
                <label htmlFor="phone" className="form-label">
                  Date Called
                </label>
                <DatePickerComponent
                  name={calledAtReg.name}
                  onChange={calledAtReg.onChange}
                  onBlur={calledAtReg.onBlur}
                  ref={calledAtReg.ref}
                />
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="tostart_at" className="form-label">
                  Date to Start
                </label>
                <DatePickerComponent
                  name={toStartAtReg.name}
                  onChange={toStartAtReg.onChange}
                  onBlur={toStartAtReg.onBlur}
                  ref={toStartAtReg.ref}
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="crew" className="form-label">
                  Crew
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("crew")}
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="completed_at" className="form-label">
                  Date Completed
                </label>
                <DatePickerComponent
                  name={completedAtReg.name}
                  onChange={completedAtReg.onChange}
                  onBlur={completedAtReg.onBlur}
                  ref={completedAtReg.ref}
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="time_spent" className="form-label">
                  Time Spent (hours)
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("time_spent")}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label htmlFor="taken_by" className="form-label">
                  Taken By
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("taken_by")}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="note" className="form-label">
                  Notes
                </label>
                <textarea
                  className="form-control"
                  style={{ height: "200px" }}
                  {...register("note")}
                ></textarea>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="images" className="form-label">
                  Images
                </label>
                <span className="ml-2">
                  <input
                    type="file"
                    className="d-none"
                    id="addImages"
                    onChange={handleAddImages}
                    multiple={true}
                  />
                  <label className="label-link" htmlFor="addImages">
                    Add Images
                  </label>
                </span>
              </div>
              <RepairImageList
                images={repair?.images}
                deleteImage={handleDeleteImage}
                previewImage={handlePreviewImage}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            <div className="alert alert-danger">
              <h4 className="alert-heading">Error!</h4>
              <p> Repair not found.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RepairDetails;
