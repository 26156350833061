import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  createPaverOrder,
  deletePaverOrder,
  fetchJobPavers,
} from "../../../services/jobPavers";
import { IPaverOrder, IPaverOrderDelivery } from "./types";
import JobCustomerInfo from "../sidePane/JobCustomerInfo";
import { BiEdit, BiTrash } from "react-icons/bi";
import { formatDate } from "../../../common/dateUtils";
import ConfirmDialog from "../../common/ConfirmDialog";
import ToastNotification from "../../common/ToastNotification";
import PaverOrdersBreadCrumbs from "./PaverOrdersBreadCrumbs";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ProgressSpinner from "../../common/ProgressSpinner";
import { showToaster } from "../../../services/toastService";

const JobPaverOrders: React.FC<any> = () => {
  const jobId = useParams().jobId;
  const [paverOrders, setPaverOrders] = useState<IPaverOrder[]>([]);
  const [job, setJob] = useState<any>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const notificationRef = useRef<any>(null);

  useEffect(() => {
    console.log("jobId: ", jobId);
    fetchJobPavers(jobId).then((resp) => {
      console.log("jobPavers: ", resp);
      if (resp.data) {
        setJob(resp.data);
        setPaverOrders(resp.data.paverorders || []);
      } else {
        const error = resp.error || "no data";
        console.log("error: ", error);
      }
    });
  }, []);

  async function handleCreateOrderClick(event: any) {
    console.log("handleCreateOrderClick");
    const data = {
      job_id: jobId,
    };
    setLoading(true);
    const resp = await createPaverOrder(data);
    setLoading(false);
    console.log("resp: ", resp);
    if (resp.data?.id) {
      const newPaverOrders = [...paverOrders, resp.data];
      setPaverOrders(newPaverOrders);
      navigate(`/paver-orders/${resp.data.id}/edit`);
    } else {
      const error = resp.error || "no data";
      showToaster(error, "error");
    }
  }

  function showConfirmationDialog(orderId: any): void {
    setSelectedOrderId(orderId);
    setShowDeleteModal(true);
  }

  async function handleDeleteOrderClick() {
    setShowDeleteModal(false);
    const resp = await deletePaverOrder(selectedOrderId);
    console.log("resp: ", resp);
    if (!resp.error) {
      const newPaverOrders = paverOrders.filter(
        (order: IPaverOrder) => order.id != selectedOrderId
      );
      setPaverOrders(newPaverOrders);
      showToastNotification("Order deleted successfully");
    } else {
      const error = resp.error || "no data";
      showToastNotification(error);
    }
  }

  async function showToastNotification(message: string) {
    if (notificationRef.current) {
      notificationRef.current.show(message);
    }
  }

  function calculateTotalDelivered(order: IPaverOrder): string {
    const totalDelivered = order.deliveries.reduce(
      (acc: number, delivery: IPaverOrderDelivery) =>
        acc + Number(delivery.qty),
      0
    );

    const totalOrdered = order.pavers.reduce(
      (acc: number, item: any) => acc + Number(item.qty),
      0
    );
    const total = totalDelivered / (totalOrdered || 1);
    return `${(total * 100).toFixed(0)}%`;
  }

  return (
    <div className="container">
      <ProgressSpinner show={loading} />
      <div className="m-1">
        <PaverOrdersBreadCrumbs job={job} />
      </div>
      <div className="row p-2 border">
        <div className="col-md-6">
          <JobCustomerInfo lead={job.lead} />
        </div>
        <div className="col">
          <div>
            <span className="text-strong">Job Title: </span>
            {job.name}
          </div>
          <div>
            <span className="text-strong">Job Number: </span>
            <Link className="text-decoration-none" to={`/jobs/${job.id}`}>
              {job.code ? job.code : job.id}
            </Link>
          </div>
        </div>
      </div>
      <div className="row my-4">
        {paverOrders.map((order: IPaverOrder) => {
          return (
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Order#: {order.order_number || order.id}
                  </h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    {order.manufacturer}
                  </h6>
                  <p className="card-text">
                    <span className="text-strong">Address: </span>
                    {order.delivery_addr}
                  </p>
                  <p className="card-text">
                    <span className="text-strong">Order Date: </span>
                    {formatDate(order.order_date)}
                  </p>
                  <p className="card-text">
                    <span className="text-strong">Date to Deliver: </span>
                    {formatDate(order.delivery_at)}
                  </p>
                  <p className="card-text">
                    <span className="text-strong">Delivered: </span>
                    {calculateTotalDelivered(order)}
                  </p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <Link
                      className="btn btn-primary btn-sm m-1 col-md-3"
                      to={`/paver-orders/${order.uid}/pdf`}
                    >
                      <PictureAsPdfIcon className="mr-1" fontSize="small" />
                      PDF
                    </Link>
                    <Link
                      className="btn btn-primary btn-sm m-1 col-md-3"
                      to={`/paver-orders/${order.id}/edit`}
                    >
                      <BiEdit className="mr-1 mb-1" />
                      Edit
                    </Link>
                    <button
                      className="btn btn-danger btn-sm m-1 col-md-3"
                      onClick={() => showConfirmationDialog(order.id)}
                    >
                      <BiTrash className="mr-1 mb-1" />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="row my-3">
        <div className="col">
          <button
            className="btn btn-secondary"
            onClick={handleCreateOrderClick}
            disabled={loading}
          >
            Create New Order
          </button>
        </div>
      </div>

      <ConfirmDialog
        show={showDeleteModal}
        message={"Are you sure you want to delete order? " + selectedOrderId}
        onHide={() => setShowDeleteModal(false)}
        handleDeleteClick={handleDeleteOrderClick}
      />
      <ToastNotification ref={notificationRef} />
    </div>
  );
};

export default JobPaverOrders;
