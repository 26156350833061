import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatErrorMessage } from "../../../common/utils";
import { upsertLabel } from "../../../services/labelsApiService";
import { showToaster } from "../../../services/toastService";
import { ILabel } from "../../lead/types";
import { getTitle } from "./LabelsEdit";

interface IProps {
  label: ILabel;
  type: string;
  show: boolean;
  onHide: () => void;
  onSaved: (label: any) => void;
}

const LabelsCreateDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");

  const { mutateAsync } = useMutation({
    mutationFn: upsertLabel,
  });
  const queryClient = useQueryClient();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    reset(props.label);
    let _title = props.label?.id ? "Edit " : "Create ";
    _title += getTitle(props.type);
    setTitle(_title);
    setShowModal(props.show);
  }, [props.show]);

  function handleCloseDialog(): void {
    setShowModal(false);
    props.onHide();
  }

  const onSubmit = (data: any) => {
    console.log("onSubmit", data);
  };

  async function handleSaveLabel() {
    await handleSubmit(onSubmit)();
    if (Object.keys(errors).length || !isDirty) return;
    const data = getValues();
    data.type = props.type;

    try {
      const resp = await mutateAsync(data);
      handleCloseDialog();
      queryClient.invalidateQueries({ queryKey: ["form-fields"] });
      showToaster(getTitle(props.type) + " saved: " + resp.data.name);
    } catch (err: any) {
      showToaster(
        "Error saving " +
          getTitle(props.type) +
          ": " +
          formatErrorMessage(err.message),
        "error"
      );
    }
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={handleCloseDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row p-3 g-3 align-items-center">
          <div className="mb-3 row">
            <label
              htmlFor="name"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Title
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Joe Doe"
                {...register("name", { required: true, minLength: 2 })}
              />
              {errors.name && errors.name.type === "required" && (
                <span className="text-danger">This is required</span>
              )}
              {errors.name && errors.name.type === "minLength" && (
                <span className="text-danger">Min length 2</span>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSaveLabel} disabled={!isDirty}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LabelsCreateDialog;
