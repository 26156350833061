export const tinyInit = {
  height: 300,
  menubar: false,
  plugins: ["wordcount"],
  toolbar:
    "undo redo | formatselect | bold italic underline | alignleft aligncenter " +
    "alignright alignjustify | removeformat",
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
};

export const dialogTinyInit = {
  height: 200,
  menubar: false,
  toolbar: "bold italic underline",
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
};

export const editorSettings = {
  items: [
    "Bold",
    "Italic",
    "Underline",
    "|",
    "JustifyLeft",
    "JustifyCenter",
    "JustifyRight",
    "JustifyFull",
    "|",
    "ClearFormat",
    "|",
    "Undo",
    "Redo",
  ],
};

// paper width: 8.5in = screen width: 912px
