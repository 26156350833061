import { currencyFormat, currencyValue } from "../../../common/utils";
import { ILineItem } from "../types";

export function calculateLineTotal(numb1: any, numb2: any) {
  const val1 = currencyValue(numb1);
  const val2 = currencyValue(numb2);
  const total = val1 == 0 && val2 == 0 ? 0 : (val1 || 1) * (val2 || 1);
  return total;
}

export function addInflationRate(
  lineItem: ILineItem,
  inflation: number
): ILineItem {
  const rate = currencyValue(lineItem.rate);
  const quantity = currencyValue(lineItem.quantity);
  const originalInflation = currencyValue(lineItem.inflation_rate);

  if (inflation !== originalInflation) {
    let newTotal = 0;
    if (lineItem.rate && rate !== 0) {
      const originalRate = currencyValue(lineItem.rate);
      const normalizedRate = originalRate / (1 + originalInflation / 100);
      const newRate = normalizedRate * (1 + inflation / 100);
      newTotal = newRate * quantity;
      lineItem.rate = currencyFormat(newRate);
    } else if (lineItem.total) {
      const originalTotal = currencyValue(lineItem.total);
      const normalizedTotal = originalTotal / (1 + originalInflation / 100);
      newTotal = normalizedTotal * (1 + inflation / 100);
    }
    lineItem.total = currencyFormat(newTotal);
    lineItem.inflation_rate = inflation;
  }
  return lineItem;
}
