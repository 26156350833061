import React from "react";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import { Link } from "react-router-dom";

const ToolboxList: React.FC = () => {
  const breadcrumbLinks = [{ to: `/settings`, label: "Settings" }];

  return (
    <>
      <div className="container">
        <BreadcrumbsComponent links={breadcrumbLinks} leaf="Toolbox" />
        <div className="py-1">
          <div className="settings-list-integrations">
            <div className="page-title">Tools</div>
            <div className="p-3 mt-3">
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">
                      <Link to="leads-import" className="text-decoration-none">
                        Import Leads
                      </Link>
                    </div>
                    Import data from an Excel file.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToolboxList;
