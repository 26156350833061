import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

interface ConfirmDialogProps {
  src: string;
  title?: string;
  show: boolean;
  onClose: (e?: any) => void;
}

const ImageViewer = (props: ConfirmDialogProps) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  function handleClose() {
    setShowModal(false);
    props.onClose();
  }

  return (
    <Modal
      keyboard={true}
      show={showModal}
      className="modal-lg"
      onHide={handleClose}
      fullscreen={"false"}
      centered
    >
      <Modal.Header
        closeButton
        style={{ background: "#777", borderBottom: "none" }}
      >
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#777", minHeight: 400 }}>
        <div className="row">
          <img src={props.src} alt="image" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageViewer;
