import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { formatErrorMessage, truncate } from "../../../common/utils";
import { upsertChecklistItem } from "../../../services/checklistApiServices";
import { IChecklist } from "./types";

interface IProps {
  item: IChecklist;
  show: boolean;
  options: string[];
  onHide: () => void;
  onSaved: (item: any) => void;
}

const ChecklistsCreateDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [title, setTitle] = React.useState("Create");
  const [options, setOptions] = React.useState<string[]>([]);

  const { mutateAsync } = useMutation({
    mutationFn: upsertChecklistItem,
  });
  const queryClient = useQueryClient();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    reset(props.item);
    let title = props.item?.id ? "Edit " : "Create ";
    setTitle(title);
    setShowModal(props.show);
    setOptions(props.options || []);
  }, [props.show]);

  function handleCloseDialog(): void {
    setShowModal(false);
    props.onHide();
  }

  async function handleSaveItem(data: any) {
    if (Object.keys(errors).length || !isDirty) return;

    try {
      const resp: any = await mutateAsync(data);
      handleCloseDialog();
      queryClient.invalidateQueries({ queryKey: ["checklist"] });
      toast.success("item saved: " + truncate(resp.data.name));
    } catch (err: any) {
      toast.error("Error saving item " + formatErrorMessage(err.message));
    }
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={handleCloseDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title} Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row p-3 g-3 align-items-center">
          <div className="mb-3 row">
            <label htmlFor="name" className="fw-semibold">
              Label:
            </label>
            <textarea
              className="form-control"
              {...register("name", { required: true, minLength: 2 })}
            />
            {errors.name && errors.name.type === "required" && (
              <span className="text-danger">This is required</span>
            )}
            {errors.name && errors.name.type === "minLength" && (
              <span className="text-danger">Min length 2</span>
            )}
          </div>
          <div className="my-3 row">
            <label htmlFor="type" className="fw-semibold col">
              Type:
            </label>
            <div className="col-sm-8">
              <input
                className="form-control"
                list="datalistOptions"
                id="exampleDataList"
                {...register("type", { required: true, minLength: 2 })}
              />
              <datalist id="datalistOptions">
                {options.map((option) => (
                  <option key={option} value={option} />
                ))}
              </datalist>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleSubmit(handleSaveItem)}
          disabled={!isDirty}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChecklistsCreateDialog;
