import React from "react";

interface Props {
  phone: string;
  register: any;
  onAddPhone?: (event: any) => void;
}

const PhoneInput: React.FC<Props> = (props) => {
  const { register, phone, onAddPhone: onAddPhoneNumber } = props;

  const onCallPhone = () => {
    window.location.href = `tel:${phone}`;
  };

  return (
    <div className="input-group">
      <input
        type="tel"
        className="form-control"
        aria-label="phone-number"
        {...register("phone")}
        placeholder="Phone #"
      />
      <div className="btn-group">
        <span className="input-group-text" id="basic-addon2">
          main
        </span>
        <button className="btn btn-default" type="button" onClick={onCallPhone}>
          <i className="bi bi-telephone-outbound-fill"></i>
        </button>
        <button
          type="button"
          className="btn btn-default dropdown-toggle dropdown-toggle-split"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="visually-hidden">Toggle Dropdown</span>
        </button>
        <ul className="dropdown-menu">
          <li>
            <a href="#" className="dropdown-item" onClick={onAddPhoneNumber}>
              <i className="bi bi-plus-square mr-2"></i>
              Add Phone#
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PhoneInput;
