import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchRepairs = async (params = {}) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/repairs", params);
    console.log("repairs fetched ", data.data.length);
    return data;
  } catch (err: any) {
    console.log("error on fetchRepairs " + err);
    throw new Error(err.message);
  }
};

export const fetchRepair = async (id: string) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/repairs/" + id);
    console.log("repair fetched ", data);
    return data;
  } catch (err: any) {
    console.log("error on fetchRepair " + err);
    return { error: err.message };
  }
};

export const fetchRepairImage = async (filename: string) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + "/repair/images/" + filename);
    console.log("repair image fetched ", data);
    return data;
  } catch (err: any) {
    console.log("error on fetchRepairImage " + err);
    return { error: err.message };
  }
};

export const addRepair = async (data: any) => {
  try {
    const apiService = new ApiService();
    const result = await apiService.post(apiUrl + "/repairs", data);
    console.log("repair added ", result);
    return result;
  } catch (err: any) {
    console.log("error on addRepair " + err);
    return { error: err.message };
  }
};

export const updateRepair = async (id: string, data: any) => {
  try {
    const apiService = new ApiService();
    const result = await apiService.put(apiUrl + "/repairs/" + id, data);
    console.log("repair updated ", result);
    return result;
  } catch (err: any) {
    console.log("error on updateRepair " + err);
    return { error: err.message };
  }
};

export const deleteRepair = async (id: string) => {
  try {
    const apiService = new ApiService();
    const result = await apiService.delete(apiUrl + "/repairs/" + id);
    console.log("repair deleted ", result);
    return result;
  } catch (err: any) {
    console.log("error on deleteRepair " + err);
    return { error: err.message };
  }
};

export const addRepairImages = async (id: string, data: any) => {
  try {
    const apiService = new ApiService();
    const result = await apiService.multiPost(
      apiUrl + `/repairs/${id}/images`,
      data
    );
    console.log("repair images added ", result);
    return result;
  } catch (err: any) {
    console.log("error on addRepairImages " + err);
    return { error: err.message };
  }
};

export const deleteRepairImage = async (id: string, filename: string) => {
  try {
    const apiService = new ApiService();
    const result = await apiService.delete(
      apiUrl + `/repairs/${id}/images/${filename}`
    );
    console.log("repair image deleted ", result);
    return result;
  } catch (err: any) {
    console.log("error on deleteRepairImage " + err);
    return { error: err.message };
  }
};

export const fetchJobRepairs = async (jobId: string) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(apiUrl + `/jobs/${jobId}/repairs`);
    return data;
  } catch (err: any) {
    console.log("error on fetchJobRepairs " + err);
    return { error: err.message };
  }
};
