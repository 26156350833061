import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineInteraction, AiOutlineSave } from "react-icons/ai";
import { BsBuildingAdd, BsTags, BsTrash } from "react-icons/bs";
import { FaDirections } from "react-icons/fa";
import { LiaUndoSolid } from "react-icons/lia";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { invalidateQueries } from "../../common/dataUtils";
import {
  fromDatabaseDateTime,
  toDatabaseDateTime,
} from "../../common/dateUtils";
import {
  formatErrorMessage,
  getCleanUrl,
  getEventTime,
  getLocation,
} from "../../common/utils";
import { googlePlaceUrl } from "../../config/app";
import { constants } from "../../config/constants";
import { PanelsContext } from "../../context/PanelsContext";
import { useUserContext } from "../../context/UserContext";
import { createEvent, deleteEvent } from "../../services/calendarApiService";
import { createJob } from "../../services/jobApiService";
import {
  deleteLead,
  fetchFormFields,
  fetchLead,
  updateLead,
} from "../../services/leadApiService";
import { showToaster } from "../../services/toastService";
import ConfirmDialog from "../common/ConfirmDialog";
import ProgressSpinner from "../common/ProgressSpinner";
import EmailAddDialog from "./components/EmailAddDialog";
import EmailDisplay from "./components/EmailDisplay";
import EmailInput from "./components/EmailInput";
import LeadJobsDisplay from "./components/LeadJobsDisplay";
import LeadLabelsPane from "./components/LeadLabelsPane";
import LeadNotes from "./components/LeadNotes";
import LeadPills from "./components/LeadPills";
import PhoneAddDialog from "./components/PhoneAddDialog";
import PhoneDisplay from "./components/PhoneDisplay";
import PhoneInput from "./components/PhoneInput";
import { IFormInput, ILabel, IPhone } from "./types";

const LeadDetails: React.FC = () => {
  const params = useParams<{ leadId: string }>();
  const navigate = useNavigate();
  const leadId = params.leadId || "0";
  const [lead, setLead] = useState<any>({});
  const [labels, setLabels] = useState<ILabel[]>([]);
  const [sources, setSources] = useState<any[]>([]);
  const [salesReps, setSalesReps] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const checkFields = { text: "name", value: "id" };
  const [state, setState] = useState<any>({});
  const [user] = useUserContext();
  const [panels, setPanels] = useState<any>({});
  const queryClient = useQueryClient();
  const location = useLocation();

  const [result, { data: leadData, isLoading, error: leadError }] = useQueries({
    queries: [
      {
        queryKey: ["form-fields"],
        queryFn: fetchFormFields,
        staleTime: Infinity,
      },
      {
        queryKey: ["lead", { leadId }],
        queryFn: () => fetchLead(leadId),
        staleTime: Infinity,
        retry: 1,
      },
    ],
  });

  const { mutateAsync } = useMutation({
    mutationFn: updateLead,
  });

  useEffect(() => {
    if (result.data) {
      processFormFields(result.data);
    }
    if (leadData) {
      const processed = processLeadData(leadData.data);
      reset(processed);
    }
  }, [leadData, result.data]);

  useEffect(() => {
    if (leadError) {
      console.log("Lead error", leadError);
      if (!leadError.message?.includes("No query results")) {
        toast.error(formatErrorMessage(leadError.message));
      }
    }
  }, [leadError]);

  function processFormFields(data: any) {
    const _data = data?.data || {};
    setSources(_data.sources);
    setSalesReps(_data.reps);
    setStatuses(_data.statuses);
    setLabels(_data.labels);
  }

  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    setValue,
    formState: { isDirty, dirtyFields, errors },
  } = useForm<IFormInput>();

  const appointmentReg = register("appointment");

  function processLeadData(data: any) {
    const sourceIds = data?.sources?.map((d: any) => d.id);
    const _data = {
      ...data,
      source_ids: sourceIds,
      appointment: fromDatabaseDateTime(data.appointment),
    };
    setLead(_data);
    return _data;
  }

  function handleAddPhone(event: any): void {
    event.preventDefault();
    setState({ ...state, showPhoneAddDialog: true });
  }

  function handlePhoneDeleted(id: string): void {
    const phones = lead.phones.filter((phone: any) => phone.id !== id);
    setLead({ ...lead, phones });
  }

  function handleEmailDeleted(id: string): void {
    const emails = lead.emails.filter((email: any) => email.id !== id);
    setLead({ ...lead, emails });
  }

  function handleAddEmail(e: any): void {
    e.preventDefault();
    setState({ ...state, showEmailAddDialog: true });
  }

  function handleDialogClose(): void {
    setState({
      ...state,
      showPhoneAddDialog: false,
      showEmailAddDialog: false,
    });
  }

  function hasCalendarIntegration(): boolean {
    const hasIntegration = user?.integrations?.some(
      (i: any) => i.name === "outlook" || i.name === "google"
    );
    return hasIntegration;
  }

  async function handleDeleteLead(event: any): Promise<void> {
    event.preventDefault();
    if (leadId) {
      const result = await Swal.fire({
        text: "Are you sure you want to delete this lead?",
        position: "top",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Delete",
      });
      if (!result.isConfirmed) {
        return;
      }
      try {
        const resp = await deleteLead(leadId);
        invalidateQueries(queryClient, ["leads"]);
        navigate(`/leads`);
      } catch (err: any) {
        showToaster(formatErrorMessage(err.message), "error");
      }
    }
  }

  async function addNewJob(event: any): Promise<void> {
    event.preventDefault();
    if (leadId) {
      setState({ ...state, showConfirmDialog: true });
    }
  }

  const handleSaveLead: SubmitHandler<IFormInput> = async (data) => {
    if (!isDirty) {
      console.log("no changes");
      return;
    }

    if (data.appointment) {
      data.appointment = toDatabaseDateTime(data.appointment);
    }
    try {
      const resp = await mutateAsync({ leadId, data });
      const message = "Lead updated successfully";
      showToaster(message, "success");
      const processed = processLeadData(resp.data);
      reset(processed);
      invalidateQueries(queryClient, ["leads", "lead", "job", "reports"]);
    } catch (err: any) {
      showToaster(formatErrorMessage(err.message), "error");
    }
  };

  function openLabelPane(): void {
    setPanels({ ...panels, labels: true });
  }

  async function handleConfirmCreateJob() {
    const resp = await createJob({
      lead_id: leadId,
    });
    if (resp.data) {
      const job = resp.data;
      navigate(`/jobs/${job.id}/edit`);
    }
  }

  function handlePhoneCreated(phone: IPhone): void {
    const phones = [...lead.phones, phone];
    setLead({ ...lead, phones });
  }

  function handleEmailCreated(email: any): void {
    const emails = [...lead.emails, email];
    setLead({ ...lead, emails });
  }

  function handleJobDeleted(id: string): void {
    const jobs = lead.jobs.filter((j: any) => j.id !== id);
    setLead({ ...lead, jobs });
    showToaster("Job deleted successfully");
  }

  const fixedTopBarClass = classNames("fixed-top-bar", {
    "d-none": !isDirty,
  });

  const saveBtnClass = classNames("btn btn-primary", {
    disabled: !isDirty,
  });

  const handleAddToCalendar = async () => {
    const data = getValues();
    if (!data.appointment) {
      Swal.fire({
        // icon: "error",
        title: "Oops...",
        text: "Please select appointment time",
        confirmButtonColor: constants.COLOR_DANGER,
        position: "top",
      });
      return;
    }
    const appointment = toDatabaseDateTime(data.appointment);
    const event = {
      title: data.customer_name,
      location: getLocation(data),
      description: getCleanUrl(window.location.href),
      start: getEventTime(appointment, 0),
      end: getEventTime(appointment, 60),
      attendees: getAttendee(lead.sales_rep),
    };

    // setLoading(true);
    const resp = await createEvent(event);
    // setLoading(false);
    if (resp.data) {
      let errorMessages = getErrorMessages(resp.data);
      if (errorMessages.length === 0) {
        showToaster("Event added.");
      } else {
        showToaster(
          `Request returned errors: ${errorMessages.join("; ")}`,
          "warning"
        );
      }
    } else {
      showToaster("Failed to add event to calendar." + resp.message, "error");
    }
  };

  function handleDiscard(): void {
    reset();
  }

  const getAttendee = (salesRep: any) => {
    if (salesRep?.email) {
      const name = salesRep.display_name || salesRep.email;
      return `${salesRep.email}:${name}`;
    }
    return null;
  };

  function getDirection(): string {
    const street = lead.street || "";
    const city = lead.city || "";
    const state = lead.state || "";
    const zip = lead.zip || "";
    const address = `${street}+${city}+${state}+${zip}`;
    return `${googlePlaceUrl}${address}`;
  }

  async function handleDeleteFromCalendar(event: any) {
    event.preventDefault();
    const response = await Swal.fire({
      position: "top",
      title: "Are you sure?",
      text: "This will remove the appointment from your calendar.",
      showCancelButton: true,
      confirmButtonColor: constants.COLOR_DANGER,
      confirmButtonText: "Delete",
    });
    if (response.isConfirmed) {
      console.log("delete from calendar");
      const data = getValues();
      const appointment = toDatabaseDateTime(data.appointment);
      const event = {
        title: data.customer_name,
        description: getCleanUrl(window.location.href),
        start: getEventTime(appointment, 0),
      };
      const resp = await deleteEvent(event);
      if (resp.data) {
        let errorMessages = getErrorMessages(resp.data);
        if (errorMessages.length === 0) {
          showToaster("Event deleted.");
          setValue("appointment", "", { shouldDirty: true });
        } else {
          showToaster(
            `Request returned errors: ${errorMessages.join("; ")}`,
            "warning"
          );
        }
      } else {
        showToaster("Failed to delete event" + resp.message, "error");
      }
    }
  }

  function getErrorMessages(data: any): string[] {
    let errorMessages = [];
    data = data || {};
    const googleError = data.google?.Error;
    if (googleError) {
      errorMessages.push(`Google: ${googleError}`);
    }

    const outlookError = data.outlook?.Error;
    if (outlookError) {
      errorMessages.push(`Outlook: ${outlookError}`);
    }
    return errorMessages;
  }

  function setPanelDisplay(panel: any, isVisible: boolean) {
    setPanels({ ...panels, [panel]: isVisible });
  }

  function handleGoBack(event: any) {
    event.preventDefault();
    navigate(`/leads`, { state: location.state });
  }

  if (leadError) {
    return (
      <div className="row p-3">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>Lead not found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="my-3 mx-3 mx-lg-5">
      <ToastContainer />
      <ProgressSpinner show={isLoading} />
      <PanelsContext.Provider value={{ panels, setPanelDisplay }}>
        <LeadLabelsPane
          leadId={leadId}
          labels={labels}
          leadLabels={lead.labels}
          updateLeadLabels={(labels) => setLead({ ...lead, labels })}
          setLoading={(value) => setState({ ...state, loading: value })}
        />
      </PanelsContext.Provider>
      <PhoneAddDialog
        leadId={leadId}
        show={state.showPhoneAddDialog}
        onHide={handleDialogClose}
        onCreated={handlePhoneCreated}
      />
      <EmailAddDialog
        leadId={leadId}
        show={state.showEmailAddDialog}
        onHide={handleDialogClose}
        onCreated={handleEmailCreated}
      />
      <ConfirmDialog
        show={state.showConfirmDialog}
        message={`Do you want to create a new job?`}
        onHide={() => setState({ ...state, showConfirmDialog: false })}
        handleDeleteClick={handleConfirmCreateJob}
        primaryButtonText="Yes"
        primaryButtonVariant="success"
      />
      <div className={fixedTopBarClass}>
        <button className={saveBtnClass} onClick={handleSubmit(handleSaveLead)}>
          <AiOutlineSave className="mr-2 mb-1" />
          Save
        </button>
        <button className="btn btn-secondary ml-3" onClick={handleDiscard}>
          <LiaUndoSolid className="mr-2 mb-1" />
          Discard
        </button>
      </div>
      <div className="panel-group py-3 px-5">
        <>
          <div className="row mb-3">
            <div className="col">
              <div className="d-flex">
                {location.state && (
                  <div className="mr-2">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleGoBack}
                    >
                      <i className="bi bi-arrow-left"></i> Back
                    </button>
                  </div>
                )}
                <div className="page-title-narrow">Lead Details</div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex justify-content-end">
                <LeadPills leadId={leadId} labels={lead.labels} />
                <div className="dropdown">
                  <button
                    className="btn btn-default dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <AiOutlineInteraction className="mr-2 mb-1" />
                    Actions
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={openLabelPane}
                      >
                        <BsTags className="mr-2 mb-1" />
                        Labels
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={handleDeleteLead}
                      >
                        <BsTrash className="mr-2 mb-1" />
                        Delete Lead
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" onClick={addNewJob}>
                        <BsBuildingAdd className="mr-2 mb-1" />
                        Add Job
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Row Customer Name */}
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="customer" className="form-label">
                Customer Name
              </label>
              <input
                type="text"
                className="form-control"
                {...register("customer_name")}
                placeholder="Joe Doe"
              />
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="contact" className="form-label">
                  Contact Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("contact_name")}
                  placeholder="Contact Name"
                />
              </div>
            </div>
          </div>

          {/* Row Phone */}
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-group phone-group">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <PhoneInput
                  phone={lead.phone}
                  register={register}
                  onAddPhone={handleAddPhone}
                />
                {/* --other phone numbers-- */}
                {lead.phones?.map((phone: any) => (
                  <PhoneDisplay
                    key={phone.id}
                    phone={phone}
                    onDeletePhone={handlePhoneDeleted}
                  />
                ))}
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <label htmlFor="contact" className="form-label">
                  Email
                </label>
                <EmailInput
                  email={lead.email}
                  register={register}
                  onAddEmail={handleAddEmail}
                />
                {
                  // --other emails--
                  lead.emails?.map((email: any) => (
                    <EmailDisplay
                      key={email.id}
                      email={email}
                      onDeleteEmail={handleEmailDeleted}
                    />
                  ))
                }
              </div>
            </div>
          </div>

          {/* row Street */}
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="street" className="form-label">
                  Street Address
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    {...register("street")}
                    placeholder="Address"
                  />
                  <span className="input-group-btn">
                    <a
                      title="Get Directions"
                      className="btn btn-outline-secondary"
                      role="button"
                      href={getDirection()}
                      target="_blank"
                    >
                      <FaDirections />
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("city")}
                  placeholder="City"
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="zip" className="form-label">
                  Zip Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("zip")}
                  placeholder="84601"
                />
              </div>
            </div>
            <div className="col-md-1">
              <div className="form-group">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  {...register("state")}
                />
              </div>
            </div>
          </div>

          {/* row Taken By */}
          <div className="row mb-3">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="takenby" className="form-label">
                  Taken by
                </label>
                {/* <select className="form-control" {...register("taken_by_id")}>
                  {takers.map((taker: any) => (
                    <option key={taker.id} value={taker.id}>
                      {taker.name}
                    </option>
                  ))}
                </select> */}
                <input
                  type="text"
                  className="form-control"
                  value={lead.created_by?.display_name}
                  placeholder="Taken By"
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="source_ids" className="form-label">
                  Sources
                </label>
                <MultiSelectComponent
                  id="defaultElement"
                  dataSource={sources}
                  fields={checkFields}
                  placeholder="Select Sources"
                  mode="CheckBox"
                  showDropDownIcon={true}
                  {...register("source_ids")}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="salesrep" className="form-label">
                  Sales Rep
                </label>
                <select className="form-control" {...register("sales_rep_uid")}>
                  {salesReps?.map((rep: any) => (
                    <option key={rep.uid} value={rep.uid}>
                      {rep.display_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select className="form-control" {...register("status_id")}>
                  {statuses?.map((status: any) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* row Appointment */}
          <div className="row mb-3">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="apptime" className="form-label">
                  Appointment Time
                </label>
                <div className="datetimepicker-control-section">
                  <DateTimePickerComponent
                    name={appointmentReg.name}
                    onChange={appointmentReg.onChange}
                    onBlur={appointmentReg.onBlur}
                    ref={appointmentReg.ref}
                  />
                </div>
              </div>
            </div>
            <div className="col mt-4">
              <div className="btn-group">
                <button
                  className="btn btn-sm btn-success"
                  type="button"
                  onClick={handleAddToCalendar}
                  disabled={!hasCalendarIntegration()}
                >
                  Add to Calendar
                </button>
                <button
                  type="button"
                  className="btn btn-success btn-sm dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={!hasCalendarIntegration()}
                >
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      href="#"
                      className="dropdown-item"
                      onClick={handleDeleteFromCalendar}
                    >
                      <i className="bi bi-trash mr-2 text-danger"></i>
                      Delete From Calendar
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="apptime" className="form-label">
                  Jobs:
                </label>
                <span>
                  <a
                    href="#"
                    onClick={addNewJob}
                    className="text-decoration-none ml-3"
                  >
                    Add Job
                  </a>
                </span>
                <LeadJobsDisplay
                  jobs={lead.jobs}
                  onDeleteJob={handleJobDeleted}
                />
              </div>
            </div>
          </div>

          {/* Row Notes      */}
          <div className="row mb-3">
            <LeadNotes leadId={lead.id} notes={lead.notes} />
          </div>

          {/* Row buttons */}
          <div className="row row-cols-lg-auto g-3">
            {/* <div className="mr-3">
              <button
                type="submit"
                className="btn btn-primary mr-3"
                disabled={!isDirty}
              >
                Save
              </button>
            </div> */}
          </div>
        </>
      </div>
    </div>
  );
};

export default LeadDetails;
