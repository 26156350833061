import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchCalendars = async (name: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(`${apiUrl}/${name}/calendars`);
    console.log("calendars fetched ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on fetchCalendars " + err);
    return { error: err.message };
  }
};

export const fetchEvents = async (name: string) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(`${apiUrl}/${name}/calendars/events`);
    console.log("events fetched ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on fetchEvents " + err);
    return { error: err.message };
  }
};

export const createEvent = async (event: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(`${apiUrl}/calendars/events`, event);
    console.log("event created ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on createEvent " + err);
    return { error: err.message };
  }
};

export const updateEvent = async (event: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(`${apiUrl}/calendars/events`, event);
    console.log("event updated ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on updateEvent " + err);
    return { error: err.message };
  }
};

export const deleteEvent = async (event: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(
      `${apiUrl}/calendars/events/delete`,
      event
    );
    console.log("event deleted ", resp);
    return resp;
  } catch (err: any) {
    console.log("error on deleteEvent " + err);
    return { error: err.message };
  }
};
