import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import JobChecklistCB from "./JobChecklistCB";
import { usePanelsContext } from "../../../context/PanelsContext";

interface IProps {
  jobId: any;
  checklist: any[];
  jobChecklist: any[];
  updateJobChecklist: any;
  register?: any;
  setLoading?: (loading: boolean) => void;
}

const JobChecklistPane: React.FC<IProps> = (props) => {
  const { jobChecklist, checklist, jobId } = props;

  const { panels, setPanelDisplay } = usePanelsContext();

  function handleClosePane() {
    setPanelDisplay("checklist", false);
  }

  return (
    <div>
      <SlidingPane
        className="sliding-pane-class"
        overlayClassName="sliding-pane-overlay"
        isOpen={panels.checklist}
        title="Checklist"
        width="40%"
        onRequestClose={handleClosePane}
      >
        <JobChecklistCB
          checklist={checklist}
          jobId={jobId}
          jobChecklist={jobChecklist}
          updateJobChecklist={(list: any) => props.updateJobChecklist(list)}
          setLoading={(isLoading) => props.setLoading(isLoading)}
        />
      </SlidingPane>
    </div>
  );
};

export default JobChecklistPane;
