import React, { useEffect, useState } from "react";
import {
  deleteIntegration,
  fetchAuthUrl,
  fetchIntegrations,
  updateIntegration,
} from "../../services/integrationsApiService";
import { IIntegration } from "./types";
import { fetchCompanyInfo } from "../../services/quickbooksApiService";
import { ICompanyInfo } from "./quickbooks/types";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { showToaster } from "../../services/toastService";
import Swal from "sweetalert2";
import { formatDateTime } from "../../common/dateUtils";
import BreadcrumbsComponent from "../common/BreadcrumbsComponent";

interface Props {
  name?: string;
}

const integrationName = "quickbooks";

const QuickbooksSettings: React.FC<Props> = () => {
  const [state, setState] = useState<any>({});
  const [integration, setIntegration] = useState<IIntegration>(null);
  const [company, setCompany] = useState<ICompanyInfo>();

  const {
    register,
    formState: { errors, isDirty },
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    fetchIntegrations(integrationName).then((res) => {
      setState({ ...state, activeTab: "settings" });
      if (res.data[0]) {
        const integration = res.data[0];
        setIntegration(integration);
        reset(integration?.settings);
      }
    });
  }, []);

  async function connectToQuickbooks(e: any) {
    e.preventDefault();
    const resp = await fetchAuthUrl(integrationName);
    if (resp.data.url) {
      window.location.href = resp.data.url;
    }
  }

  async function getCompanyInfo() {
    console.log("getCompanyInfo");
    const resp = await fetchCompanyInfo(integration.id);
    if (resp.data) {
      setCompany(resp.data);
    }
  }

  async function handleDeleteIntegration(e: any) {
    e.preventDefault();
    if (integration.id) {
      console.log("handleDeleteIntegration " + integration.id);
      const result = await Swal.fire({
        text: "Are you sure you want to delete this integration?",
        position: "top",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Delete",
      });
      if (result.isConfirmed) {
        const resp = await deleteIntegration(integration.id);
        if (resp.status == "success") {
          setIntegration(null);
        }
      }
    }
  }

  async function handleSaveIntegration(e: any) {
    e.preventDefault();
    if (isDirty && integration.id) {
      const values = getValues();
      console.log("handleSaveIntegration " + integration.id);

      const resp = await updateIntegration(integration.id, values);
      if (resp.data) {
        const integration = resp.data;
        setIntegration(integration);
        reset(integration.settings);
        showToaster("Integration saved successfully.");
      }
    }
  }

  const settingsTabPaneClass = classNames("mt-3 tab-pane fade", {
    "show active": state?.activeTab == "settings",
    "d-none": state?.activeTab != "settings",
  });

  const debugTabPaneClass = classNames("mt-3 tab-pane fade", {
    "show active": state?.activeTab == "debug",
    "d-none": state?.activeTab != "debug",
  });

  const settingsTabClass = classNames("nav-link", {
    active: state?.activeTab == "settings",
  });

  const debugTabClass = classNames("nav-link", {
    active: state?.activeTab == "debug",
  });

  function showTab(e: any) {
    e.preventDefault();
    const name = e.target.id;
    setState({ ...state, activeTab: name });
  }

  const breadcrumbsLinks = [
    { to: "/settings", label: "Settings" },
    { to: "/settings/integrations", label: "Integrations" },
  ];

  return (
    <div className="container">
      <BreadcrumbsComponent links={breadcrumbsLinks} leaf="Quickbooks" />
      <h3>QuickBooks Online Integration</h3>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className={settingsTabClass}
            aria-current="page"
            href="#"
            id="settings"
            onClick={showTab}
          >
            Settings
          </a>
        </li>
        <li className="nav-item">
          <a className={debugTabClass} href="#" id="debug" onClick={showTab}>
            Debug Info
          </a>
        </li>
      </ul>
      <div className={settingsTabPaneClass}>
        <div className="ml-3 my-3 d-flex justify-content-start">
          <a
            className="btn btn-outline-secondary btn-sm"
            href="#"
            onClick={connectToQuickbooks}
          >
            {!integration?.is_active
              ? "Connect to QuickBooks"
              : "Renew Connection"}
          </a>
          {integration?.is_active && (
            <button
              className="btn btn-outline-secondary btn-sm ml-3"
              onClick={getCompanyInfo}
            >
              Get Company Info
            </button>
          )}
        </div>
        {integration && (
          <div className="mx-3">
            <div className="mb-2">
              <span className="text-strong">Status:</span>{" "}
              {integration.is_active ? "Active" : "Inactive"}
              <a
                className="ml-3 text-decoration-none"
                href="#"
                onClick={handleDeleteIntegration}
              >
                Disconnect
              </a>
            </div>

            <div className="mb-2">
              <span className="mr-5 text-strong">Company:</span>
              <span className="ml-5 text-secondary">
                {integration?.settings?.companyName || "Not Available"}
              </span>
              {integration?.settings?.realmId && (
                <span className="ml-3 text-secondary">
                  ({integration.settings.realmId})
                </span>
              )}
            </div>
            <div className="row mb-2">
              <div className="col-sm-2 text-strong">Discount Item Name:</div>
              <div className="col-sm-4">
                <input
                  className="form-control form-control-sm"
                  defaultValue={integration?.settings?.discountItemName}
                  {...register("discountItemName")}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-2 text-strong">Service Item Name:</div>
              <div className="col-sm-4">
                <input
                  className="form-control form-control-sm"
                  defaultValue={integration?.settings?.serviceItemName}
                  {...register("serviceItemName")}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-2 text-strong">Sales Terms Name:</div>
              <div className="col-sm-4">
                <input
                  className="form-control form-control-sm"
                  defaultValue={integration?.settings?.salesTermName}
                  {...register("salesTermName")}
                />
              </div>
              <div className="row">
                <div className="col-sm-6 mt-4 text-center">
                  <button
                    className="btn btn-primary btn-sm mr-5"
                    onClick={handleSaveIntegration}
                    disabled={!isDirty}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={debugTabPaneClass}>
        <div style={{ margin: "40px 10px" }}>
          <div>
            <label className="text-strong">Access Token</label>
            <textarea
              className="form-control form-control-sm mt-2"
              style={{ height: "148px" }}
              readOnly={true}
              value={integration?.access_token}
            ></textarea>
          </div>
          <div className="mt-1 text-sm">
            <label className="text-strong">Expires at: </label>
            <span className="ml-2 mt-1 text-secondary">
              {formatDateTime(integration?.expires_at)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickbooksSettings;
