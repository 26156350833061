import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

interface IProps {
  base64String: string;
  show: boolean;
  onClose: () => void;
}

export const PDFPreview: React.FC<IProps> = (props) => {
  const { base64String, onClose } = props;
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (base64String) {
      const pdfUrl = convertBase64ToPdfUrl(base64String);
      setPdfUrl(pdfUrl);
    }
  }, [base64String]);

  function convertBase64ToPdfUrl(base64String: string) {
    const base64Data = base64String
      .replace(/^data:application\/pdf;base64,/, "")
      .replace(/\s/g, "");

    // Decode base64 string
    const binaryString = atob(base64Data);

    const bytes = new Uint8Array(
      Array.from(binaryString, (char) => char.charCodeAt(0))
    );
    const pdfBlob = new Blob([bytes.buffer], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    return pdfUrl;
  }

  return (
    <Modal
      keyboard={true}
      show={props.show}
      className="modal-lg"
      onHide={props.onClose}
      fullscreen={"false"}
      centered
    >
      <Modal.Header
        closeButton
        style={{ background: "#777", borderBottom: "none" }}
      >
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#fff", minHeight: 400 }}>
        {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px" />}
      </Modal.Body>
    </Modal>
  );
};

export default PDFPreview;
