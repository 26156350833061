import React, { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { useUserContext } from "../../context/UserContext";

const Profile: React.FC<any> = () => {
  const [showModal, setShowModal] = useState(false);
  const [user] = useUserContext();

  return (
    <>
      <ChangePasswordDialog
        user={user}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <ToastContainer />
      <div className="my-5">
        <form>
          <div className="form-group row m-3">
            <label
              htmlFor="inlineFormInputName2"
              className="col-sm-2 col-form-label"
            >
              Name:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="inlineFormInputName2"
                value={user?.name}
                disabled
              />
            </div>
          </div>
          <div className="form-group row m-3">
            <label
              htmlFor="inlineFormInputEmail"
              className="col-sm-2 col-form-label"
            >
              Email:
            </label>
            <div className="col-sm-10">
              <input
                type="email"
                className="form-control"
                id="inlineFormInputEmail"
                value={user.email}
                disabled
              />
            </div>
          </div>

          <div className="form-group row m-3">
            <label
              htmlFor="inlineFormInputEmail"
              className="col-sm-2 col-form-label"
            >
              Role:
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="inlineFormInputEmail"
                value={user.role?.name}
                disabled
              />
            </div>
            <div className="row mt-5">
              <button
                type="button"
                className="btn btn-primary col-3 d-flex flex-row justify-content-center align-items-center mx-auto"
                onClick={() => setShowModal(true)}
              >
                Change Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Profile;
