import { BiTrash } from "react-icons/bi";
import { IPaverOrderItem } from "./types";
import { Paper } from "@mui/material";

interface IProps {
  item: IPaverOrderItem;
  register: any;
  index: number;
  deleteItem: (id: number) => void;
}

const PaverOrderItem: React.FC<IProps> = (props) => {
  const { register, deleteItem, index, item } = props;
  const itemId = item.id || 0;

  return (
    <div className="mb-3">
      <Paper>
        <div className="p-4 m-2">
          <div className="row mb-3">
            <div className="col-md-3">
              <label htmlFor="paver" className="form-label">
                Paver Name
              </label>
              <input
                type="text"
                className="form-control"
                {...register(`pavers.${index}.paver`)}
                defaultValue={item.paver}
                placeholder="Colonial"
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="color" className="form-label">
                Paver Color
              </label>
              <input
                type="text"
                className="form-control"
                {...register(`pavers.${index}.color`)}
                defaultValue={item.color}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="size" className="form-label">
                Paver Size
              </label>
              <input
                type="text"
                className="form-control"
                {...register(`pavers.${index}.size`)}
                defaultValue={item.size}
                placeholder="Paver Size"
              />
            </div>
            <div className="col-sm-3">
              <label htmlFor="palets" className="form-label">
                Quantity
              </label>
              <div className="input-group" id="">
                <input
                  type="text"
                  className="form-control"
                  {...register(`pavers.${index}.qty`)}
                  defaultValue={item.qty}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="pallet"
                  {...register(`pavers.${index}.qty_unit`)}
                  defaultValue={item.qty_unit}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3 align-items-end">
            <div className="col-md-2">
              <label htmlFor="sqft" className="form-label">
                SQ/FT
              </label>
              <input
                type="text"
                className="form-control"
                {...register(`pavers.${index}.sqft`)}
                defaultValue={item.sqft}
                placeholder="0"
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="weight" className="form-label">
                Weight
              </label>
              <input
                type="text"
                className="form-control"
                {...register(`pavers.${index}.weight`)}
                defaultValue={item.weight}
                placeholder="0"
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="price" className="form-label">
                Price
              </label>
              <input
                type="text"
                className="form-control"
                {...register(`pavers.${index}.price`)}
                defaultValue={item.price}
                placeholder="0.00"
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="deposit" className="form-label">
                Deposit /pallet.
              </label>
              <input
                type="text"
                className="form-control"
                {...register(`pavers.${index}.deposit`)}
                defaultValue={item.pallet_deposit}
                placeholder="15.00"
              />
            </div>
            <div className="col-md-2  d-flex justify-content-end">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  {...register(`pavers.${index}.tumbled`)}
                  defaultChecked={item.tumbled == 1}
                />
                <label className="form-check-label ml-2" htmlFor="tumbled">
                  Tumbled
                </label>
              </div>
            </div>
            <div className="col-md-2 text-end">
              {item.id && (
                <button
                  className="btn btn-outline-danger"
                  title="delete line"
                  onClick={() => deleteItem(itemId)}
                >
                  <BiTrash className="mr-1 mb-1" />
                </button>
              )}
            </div>
          </div>
          <input
            type="hidden"
            {...register(`pavers.${index}.id`)}
            defaultValue={item.id || 0}
          />
        </div>
      </Paper>
    </div>
  );
};

export default PaverOrderItem;
