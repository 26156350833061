import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchReports = async () => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + `/reports`);
    console.log("reports fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchReports ", err);
    throw new Error(err.message);
  }
};

export const fetchGoals = async () => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.get(apiUrl + `/goals`);
    console.log("goals fetched ");
    return resp;
  } catch (err: any) {
    console.log("error on fetchGoals ", err);
    return { error: err.message };
  }
};

export const updateGoals = async (data: any) => {
  const apiService = new ApiService();
  const resp = await apiService.post(apiUrl + `/goals`, data);
  console.log("goals updated ");
  return resp;
};
