import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchCompanyInfo = async (id: string) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.get(`${apiUrl}/quickbooks/company-info`);
    console.log("company info fetched ", data);
    return data;
  } catch (err: any) {
    console.log("error on fetchCompanyInfo " + err);
    return { error: err.message };
  }
};

export const fetchCustomers = async (id: string) => {};

export const fetchInvoices = async (id: string) => {};

export const createCustomer = async (lead_id: number) => {
  try {
    const apiService = new ApiService();
    const data = await apiService.post(`${apiUrl}/quickbooks/customers`, {
      lead_id,
    });
    console.log("customer created ", data);
    return data;
  } catch (err: any) {
    console.log("error on createCustomer " + err);
    return { error: err.message };
  }
};

export const createInvoice = async (job_id: number) => {
  const apiService = new ApiService();
  const data = await apiService.post(`${apiUrl}/quickbooks/invoices`, {
    job_id,
  });
  console.log("invoice created ", data);
  return data;
};
