import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { googleApiKey } from "../../config/app";
import { fetchSoldJobs } from "../../services/jobApiService";
import { getQueryParams as getQueryParam, wait } from "../../common/utils";
import {
  faMapPin,
  faHome,
  faMapMarked,
} from "@fortawesome/free-solid-svg-icons";

const JobsMapView: React.FC = () => {
  const [map, setMap] = React.useState(null);
  const markers: any[] = [];

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: 40.44055178436399,
    lng: -111.71697774858333,
  };

  const provo = {
    lat: 40.23384,
    lng: -111.65853,
  };

  const onMapLoad = React.useCallback(async function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);

    // map.fitBounds(bounds);
    setMap(map);
    placeCenterPin(map);
    const size = getQueryParam("size");
    const resp = await fetchSoldJobs({ size });
    if (resp.data) {
      await placeJobPins(resp.data, map);
    } else {
      console.log("error fetching jobs", resp.error);
    }
  }, []);

  const onMapUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const placeCenterPin = (map: any) => {
    // setTimeout(() => {
    const marker = new window.google.maps.Marker({
      position: provo,
      map,
      title: "provo",
    });
    marker.setMap(map);
    // }, 5000);
  };

  const placeJobPins = async (jobs: any[], map: any) => {
    // const path: any = faHome.icon[4];
    const path: any = faMapPin.icon[4];
    for (let job of jobs) {
      const lead = job.lead || {};
      if (lead && !lead.geocode) {
        const address = `${lead.street},${lead.city},${lead.state}`;
        console.log("address", address);
        const code = await getGeocode(address);
        lead.geocode = code;
        console.log("geocode", code);
      }

      const position = getPosition(lead.geocode);

      var marker = new google.maps.Marker({
        map: map,
        position: position,
        animation: google.maps.Animation.DROP,
        title: lead.contact_name || "Job",
        icon: {
          path: path,
          scale: 0.07,
          strokeWeight: 1,
          strokeColor: "purple",
          strokeOpacity: 1,
          fillColor: "blue",
          fillOpacity: 0.8,
        },
        // using a custom icon
        // icon: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        // icon: {
        //   path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
        //   strokeColor: "#b41412",
        //   // strokeColor: "#000",
        //   fillColor: "#ea4335",
        //   fillOpacity: 0.8,
        //   strokeWeight: 2,
        //   scale: 7,
        // },
      });

      marker.setMap(map);
      markers.push(marker);

      wait(1000);
      //   const hlink = `https://www.google.com/maps/dir/Current+Location/${job.geocode.lat},${job.geocode.lng}`;

      //   google.maps.event.addListener(marker, 'click', function() {
      //     infoWindow.setContent(`<div style="font-weight:bold">${job.name}</div>
      //     <a style="font-size:small" target="_blank" href="${hlink}">Get Directions</a>`);

      //     infoWindow.open(map, this);
      //   });
    }
    console.log("job pins placed");
  };

  function initMap() {
    const geocoder = new google.maps.Geocoder();
    const infoWindow = new google.maps.InfoWindow();
    const defaultLocation = provo;

    const map = new google.maps.Map(document.getElementById("jobs-map"), {
      center: defaultLocation,
      zoom: 10,
    });

    var marker = new google.maps.Marker({
      position: defaultLocation,
      map: map,
      animation: google.maps.Animation.DROP,
      //   icon: curr_location_icon,
      title: "Office",
    });

    marker.setMap(map);
  }

  const getPosition = (geocode: any) => {
    if (!geocode) return provo;
    try {
      const result = JSON.parse(geocode);
      return result;
    } catch (err) {
      return provo;
    }
  };

  const getGeocode = (address: string) => {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, function (results, status) {
        if (status == "OK") {
          const loc = results[0].geometry.location;
          resolve({ lat: loc.lat(), lng: loc.lng() });
        } else {
          console.log(
            "Geocode was not successful for the following reason: " + status
          );
          resolve({ lat: 0, lng: 0 });
        }
      });
    });
  };

  return (
    <LoadScript googleMapsApiKey={googleApiKey}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
      ></GoogleMap>
    </LoadScript>
  );
};

export default JobsMapView;
