import { authUrl } from "../config/app";
import { IAuthToken, IAuthUser } from "./types.d";

export const login = async (data: any) => {
  const result = {
    error: "",
    data: {} as IAuthToken,
  };

  const options: any = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(authUrl, options);
    const data = await response.json();
    if (data.access_token) {
      result.data = data;
      result.error = "";
      return result;
    } else {
      result.error = data.message;
      return result;
    }
  } catch (err: any) {
    if (err.message?.includes("Unexpected token")) {
      result.error = "Server error. Please try again later.";
    } else {
      result.error = err.message;
    }
    return result;
  }
};

export const saveAuthToken = (authResult: IAuthToken) => {
  // Set the time that the access token will expire at
  let expiresAt =
    Number(authResult.expires_in || 0) * 1000 + new Date().getTime();
  localStorage.setItem("access_token", authResult.access_token || "");
  localStorage.setItem("refresh_token", authResult.refresh_token || "");
  localStorage.setItem("expires_at", expiresAt.toString());
};

export const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user");
};

export const isAuthenticated = () => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    return false;
  }
  //check if token is expired
  const expiresAt = localStorage.getItem("expires_at");
  const currentTime = new Date().getTime();
  if (currentTime > parseInt(expiresAt || "0")) {
    return false;
  }
  return true;
};
