import { Marker } from "@react-google-maps/api";
import { AdvancedMarker, Pin, useMap } from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import { IJobProgressLead } from "./types";
import { getMarkerBgColor } from "./JobProgressService";

const JobMarkers: React.FC<{ markers: any[] }> = (props) => {
  const map = useMap();
  const infoWindow: any = new google.maps.InfoWindow();
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});

  useEffect(() => {
    console.log("points", props.markers, map);
  }, [map, props.markers]);

  function handleMarkerClick(marker: any, index: number) {
    const lead: IJobProgressLead = marker.lead || ({} as any);
    const directions = `https://www.google.com/maps/dir/Current+Location/${marker.lead.street},${marker.lead.city},${marker.lead.state}`;
    infoWindow.setHeaderContent(lead.customer_name);
    infoWindow.setContent(
      `<div style="font-weight:normal;">${lead.street}<br>${lead.city}, ${lead.state}</div>
        <a style="font-size:small" target="_blank" href="${directions}">Get Directions</a>
        <br />`
    );
    const anchor = markers[index];
    infoWindow.open(map, anchor);
  }

  const setMarkerRef = (marker: Marker | null, key: number) => {
    if ((marker && markers[key]) || (!marker && !markers[key])) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  return (
    <>
      {props.markers?.map((marker, index) => (
        <AdvancedMarker
          key={marker.title + index}
          position={marker.position}
          title={marker.title}
          onClick={(e) => {
            handleMarkerClick(marker, index);
          }}
          ref={(m: any) => setMarkerRef(m, index)}
        >
          <Pin
            background={getMarkerBgColor(marker.status)}
            glyphColor={"#444"}
            borderColor={"#000"}
          />
        </AdvancedMarker>
      ))}
    </>
  );
};

export default JobMarkers;
