import { createContext, useContext } from "react";

export const JobContext = createContext<any>(null);

export function useJobContext() {
  const [setIsLineDirty] = useContext(JobContext);

  if (setIsLineDirty == undefined) {
    throw new Error("useJobContext must be used within a JobProvider");
  }

  return [setIsLineDirty];
}
