import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { PiSelectionBackgroundBold } from "react-icons/pi";
import { getFileSize, isFileTooLarge } from "../../../common/utils";
import { toast } from "react-toastify";

const MAX_FILE_SIZE_MB = 1;

interface IProps {
  show: boolean;
  onHide: (e?: any) => void;
  onSave: (file: File) => void;
}

const LogoUpdateDialog: React.FC<IProps> = (props) => {
  const [file, setFile] = useState<File | null>(null);

  function handleCancelClick(): void {
    setFile(null);
    props.onHide();
  }

  async function handleSaveClick(event: any) {
    console.log("handleSaveClick", event);
    props.onSave(file);
  }

  function updateImageDisplay(e: any) {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }
    if (isFileTooLarge(file, MAX_FILE_SIZE_MB)) {
      toast.error(`File size should be less than ${MAX_FILE_SIZE_MB}MB`);
      setFile(null);
      return;
    }

    const fileSize = getFileSize(file);
    setFile(file);
    console.info(`File name ${file.name}, file size ${fileSize}.`);
  }

  return (
    <Modal
      show={props.show}
      backdrop="static"
      keyboard={false}
      onHide={props.onHide}
      className="modal-dialog-layer"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-5 my-3">
          <div className="form-group mb-3 ">
            <div className="row m-3">
              <div
                className="preview col-auto border rounded border-secondary p-1 m-1"
                style={{ height: 100, minWidth: 100 }}
              >
                {file && (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="preview..."
                    style={{ height: 100 }}
                  />
                )}
              </div>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <label htmlFor="inputFile" className="btn btn-secondary mb-1">
                <input
                  type="file"
                  id="inputFile"
                  accept=".png"
                  className="d-none"
                  onChange={updateImageDisplay}
                />
                <PiSelectionBackgroundBold className="mr-2" />
                Choose file
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSaveClick} disabled={!file}>
          Save
        </Button>
        <Button variant="danger" onClick={handleCancelClick}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoUpdateDialog;
