export const constants = {
  SUCCESS_STATUS: "success",
  ERROR_STATUS: "error",
  FAIL_STATUS: "fail",
  MAX_RESULTS: 100,
  COLOR_PRIMARY: "#007bff",
  COLOR_SECONDARY: "#6c757d",
  COLOR_SUCCESS: "#28a745",
  COLOR_DANGER: "#dc3545",
  DEFAULT_TIMEZONE: "America/Denver",
};

export const phoneRegex = /^(\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/;

export const colors = {
  red: "#FF0000",
  redLight: "#FA7F7F",
  pink: "#ff8fec",
  green: "#00ff7f",
  green2: "#00CC66",
  blue: "#2274A5",
  orange: "#F75C03",
  gray: "#A7A9AC",
  black: "#000000",
  powderBlue: "#93BEDF",
  iceBlue: "#8EF9F3",
  purple: "#6A0572",
  magenta: "#922D50",
  yellow: "#ffae00",
};
