import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchLabels = async (id: string = "") => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/labels" + (id ? "/" + id : "");
    const resp = await apiService.get(url);
    return resp;
  } catch (err: any) {
    console.log("error on fetchLabels " + err);
    throw err;
  }
};

export const upsertLabel = async (data: any) => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/labels";
    const resp = await apiService.post(url, data);
    return resp;
  } catch (err: any) {
    console.log("error on upsertLabel " + err);
    throw err;
  }
};

export const upsertFormInput = async (type: string, data: any) => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/form-inputs/" + type;
    const resp = await apiService.post(url, data);
    return resp;
  } catch (err: any) {
    console.log("error on upsertFormInput " + err);
    return { error: err.message };
  }
};

export const deleteLabel = async (id: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/labels/" + id);
    return resp;
  } catch (err: any) {
    console.log("error on deleteLabel " + err);
    throw err;
  }
};

export const deleteLeadLabel = async (labelId: any) => {
  const apiService = new ApiService();
  const resp = await apiService.delete(apiUrl + "/lead/labels/" + labelId);
  return resp;
};

export const deleteJobLabel = async (labelId: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/job/labels/" + labelId);
    return resp;
  } catch (err: any) {
    console.log("error on deleteJobLabel " + err);
    return { error: err.message };
  }
};
