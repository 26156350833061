import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { fetchTemplates } from "../../../services/proposalApiService";
import CreateTemplateDialog from "./CreateTemplateDialog";
import ProposalTemplatesList from "./ProposalTemplatesList";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { formatErrorMessage } from "../../../common/utils";
import { IProposalTemplate } from "../../job/types";

interface Props {
  name?: string;
}

const ProposalTemplates: React.FC<Props> = () => {
  const [templates, setTemplates] = useState<any>([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [template, setTemplate] = useState<any>({});

  const { data, isError, error } = useQuery({
    queryKey: ["proposal-templates"],
    queryFn: () => fetchTemplates(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setTemplates(data?.data || []);
    if (isError) {
      const msg = formatErrorMessage(error.message);
      console.log("Error fetching templates: ", msg);
      toast.error(msg);
    }
  }, [data, error]);

  function openDialog(): void {
    setTemplate({});
    setDialogIsOpen(true);
  }

  function onDialogClose(): void {
    setDialogIsOpen(false);
  }

  function updateTemplatesList(template: any): void {
    setTemplates([template, ...templates]);
  }

  function handleListUpdated(templates: IProposalTemplate[]): void {
    setTemplates(templates);
  }

  return (
    <div className="container">
      <BreadcrumbsComponent
        links={[{ to: "/settings", label: "Settings" }]}
        leaf="Proposal Templates"
      />
      <div className="row mb-3">
        <div className="col">
          <div className="page-title">Proposal Templates</div>
        </div>
        <div className="col-auto">
          <button className="btn btn-default" onClick={openDialog}>
            <BsPencilSquare className="mr-2" />
            Add Template
          </button>
        </div>
      </div>
      <CreateTemplateDialog
        isOpen={dialogIsOpen}
        onDialogClosed={onDialogClose}
        onTemplateUpdated={updateTemplatesList}
        template={template}
      />
      <ProposalTemplatesList
        templates={templates}
        onListUpdated={handleListUpdated}
      />
    </div>
  );
};

export default ProposalTemplates;
