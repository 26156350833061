import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter } from "react-router-dom";
import Privacy from "../components/about/Privacy";
import Login from "../components/auth/Login";
import Login2 from "../components/auth/Login2";
import GoogleCalendarSettings from "../components/integrations/google/GoogleCalendarSettings";
import GoogleMapsSettings from "../components/integrations/google/GoogleMapsSettings";
import QuickbooksSettings from "../components/integrations/QuickbooksSettings";
import JobDetails from "../components/job/JobDetails";
import JobEdit from "../components/job/JobEdit";
import JobList from "../components/job/JobList";
import JobPaverOrders from "../components/job/pavers/JobPaverOrders";
import PaverOrderEdit from "../components/job/pavers/PaverOrderEdit";
import PDFViewer from "../components/job/pdf/PDFViewer";
import LeadDetails from "../components/lead/LeadDetails";
import LeadList from "../components/lead/LeadList";
import IntegrationsList from "../components/settings/integration/IntegrationsList";
import PricesEdit from "../components/settings/prices/PricesEdit";
import ProposalTemplates from "../components/settings/template/ProposalTemplates";
import SettingsList from "../components/settings/SettingsList";
import UsersEdit from "../components/settings/user/UsersList";
import Profile from "../components/user/Profile";
import ErrorPage from "../misc/error-page";
import PrivateRoutes from "./PrivateRoutes";
import InstallerPaymentEdit from "../components/job/installer/InstallerPaymentEdit";
import JobsMapView from "../components/map/JobsMapView";
import Home from "../components/home/Home";
import Test from "../components/lead/Test";
import FormInputs from "../components/settings/formInput/FormInputs";
import LabelsEdit from "../components/settings/label/LabelsEdit";
import Goals from "../components/report/Goals";
import CompanyDetails from "../components/settings/company/CompanyDetails";
import OutlookSettings from "../components/integrations/outlook/OutlookSettings";
import RepairList from "../components/repair/RepairList";
import RepairDetails from "../components/repair/RepairDetails";
import JobRepairList from "../components/repair/JobRepairList";
import ChecklistsEdit from "../components/settings/checklist/ChecklistsEdit";
import JobProgress from "../components/job/progress/JobProgress";
import InstallerPaymentsList from "../components/job/installer/InstallerPaymentsList";
import ToolboxList from "../components/settings/toolbox/ToolboxList";
import LeadsImport from "../components/settings/toolbox/LeadsImport";
import TermsOfService from "../components/about/TermsOfService";
import JobInstallerPayments from "../components/job/installer/JobInstallerPayments";
import RootNavBar from "./RootNavBar.";
import JobProgressMap from "../components/job/progress/JobProgressMap";
import PowerBI from "../components/report/PowerBI";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <RootNavBar />,
    errorElement: <ErrorPage />,
    // todo find out what loader is for
    // loader: rootLoader,
    children: [
      {
        element: <PrivateRoutes />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "home",
            element: <Home />,
          },
          {
            path: "leads",
            element: <LeadList />,
          },
          {
            path: "leads/:leadId",
            element: <LeadDetails />,
          },
          {
            path: "jobs",
            element: <JobList />,
          },
          {
            path: "jobs/progress",
            element: <JobProgress />,
          },
          {
            path: "jobs/progress/map",
            element: <JobProgressMap />,
          },
          {
            path: "installer-payments",
            element: <InstallerPaymentsList />,
          },
          {
            path: "jobs/:jobId",
            element: <JobDetails />,
          },
          {
            path: "jobs/:jobId/edit",
            element: <JobEdit />,
          },
          {
            path: "jobs/:id/pdf",
            element: <PDFViewer model="pdf" />,
          },
          {
            path: "jobs/:id/installer",
            element: <PDFViewer model="installer" />,
          },
          {
            path: "jobs/:jobId/pavers",
            element: <JobPaverOrders />,
          },
          {
            path: "paver-orders/:orderId/edit",
            element: <PaverOrderEdit />,
          },
          {
            path: "paver-orders/:id/pdf",
            element: <PDFViewer model="paver-orders" />,
          },
          {
            path: "settings",
            element: <SettingsList />,
          },
          {
            path: "settings/users",
            element: <UsersEdit />,
          },
          {
            path: "settings/integrations",
            element: <IntegrationsList />,
          },
          {
            path: "settings/proposal-templates",
            element: <ProposalTemplates />,
          },
          {
            path: "settings/form-inputs",
            element: <FormInputs />,
          },
          {
            path: "settings/prices",
            element: <PricesEdit />,
          },
          {
            path: "settings/labels",
            element: <LabelsEdit />,
          },
          {
            path: "settings/checklists",
            element: <ChecklistsEdit />,
          },
          {
            path: "settings/integrations",
            element: <IntegrationsList />,
          },
          {
            path: "settings/company",
            element: <CompanyDetails />,
          },
          {
            path: "settings/toolbox",
            element: <ToolboxList />,
          },
          {
            path: "settings/toolbox/leads-import",
            element: <LeadsImport />,
          },
          {
            path: "integrations/quickbooks",
            element: <QuickbooksSettings />,
          },
          {
            path: "integrations/google-calendar",
            element: <GoogleCalendarSettings />,
          },
          {
            path: "integrations/google-maps",
            element: <GoogleMapsSettings />,
          },
          {
            path: "integrations/outlook",
            element: <OutlookSettings />,
          },
          {
            path: "installer-payments/:id",
            element: <InstallerPaymentEdit />,
          },
          {
            path: "jobs/:id/installer-payments",
            element: <JobInstallerPayments />,
          },
          {
            path: "repairs",
            element: <RepairList />,
          },
          {
            path: "repairs/:id",
            element: <RepairDetails />,
          },
          {
            path: "repairs/:id/pdf",
            element: <PDFViewer model="repair" />,
          },
          {
            path: "jobs/:id/repairs",
            element: <JobRepairList />,
          },
          {
            path: "reports/goals",
            element: <Goals />,
          },
          {
            path: "reports/power-bi",
            element: <PowerBI />,
          },
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "test",
            element: <Test />,
          },
        ],
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "login2",
    element: <Login2 />,
  },
  {
    path: "maps/jobs",
    element: <JobsMapView />,
  },
  {
    path: "terms",
    element: <TermsOfService />,
  },
  {
    path: "privacy",
    element: <Privacy />,
  },
]);

export default AppRouter;
