import React, { useEffect, useState } from "react";
import { ISketch } from "../types";
import { getVisibility } from "../../../common/utils";
import { PiImagesSquareDuotone } from "react-icons/pi";

interface IProps {
  jobSketches: ISketch[];
  editSketch: (id: any) => void;
  deleteSketch: (id: any) => void;
  previewSketch: (id: any) => void;
}

const JobSketchList: React.FC<IProps> = (props) => {
  const [jobSketches, setJobSketches] = useState<ISketch[]>([]);

  useEffect(() => {
    setJobSketches(props.jobSketches || []);
  }, [props.jobSketches]);

  function editSketch(event: any, id: any): void {
    event.preventDefault();
    if (id) {
      props.editSketch(id);
    }
  }

  function deleteSketch(event: any, id: any): void {
    event.preventDefault();
    if (id) {
      props.deleteSketch(id);
    }
  }

  function previewSketch(event: any, id: any): void {
    event.preventDefault();
    if (id) {
      props.previewSketch(id);
    }
  }

  return (
    <div>
      <div className="row m-2">
        {jobSketches.map((sketch) => (
          <div
            key={sketch.id}
            className="card bg-light mr-3"
            style={{ width: "160px" }}
          >
            <button
              className="btn btn-outline-secondary my-2 p-3"
              onClick={(e) => previewSketch(e, sketch.id)}
            >
              <PiImagesSquareDuotone fontSize={48} />
            </button>
            <div className="card-body">
              <div className="card-text">{sketch.label}</div>
            </div>
            <div className="d-flex justify-content-start">
              <span className="badge rounded-pill text-bg-secondary ml-1">
                {getVisibility(sketch.visibility)}
              </span>
            </div>
            <div className="mt-1 mb-2 mr-1 d-flex justify-content-end">
              <a
                href="#"
                className="text-decoration-none"
                onClick={(e) => editSketch(e, sketch.id)}
              >
                <i className="bi bi-pencil-square mr-1"></i>
              </a>
              <a
                href="#"
                className="text-decoration-none text-danger ml-5"
                onClick={(e) => deleteSketch(e, sketch.id)}
              >
                <i className="bi bi-trash mr-1"></i>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobSketchList;
