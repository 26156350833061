import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  formatAppointmentDate,
  formatAppointmentDay,
  formatAppointmentTme,
} from "../../common/dateUtils";
import { ensureDate } from "../../common/dateUtils";

interface IProps {
  events: any;
}

const AppointmentList: React.FC<IProps> = (props) => {
  const [events, setEvents] = useState<any>([]);

  useEffect(() => {
    const grouped = groupEventsByDate(props.events || []);
    const sorted = sortEventsByDate(grouped);
    setEvents(sorted);
  }, [props.events]);

  function groupEventsByDate(events: any[]) {
    return events.reduce((groupedEvents, event) => {
      const date = ensureDate(event.start);
      const dateStr = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
      if (!groupedEvents[dateStr]) {
        groupedEvents[dateStr] = [];
      }
      groupedEvents[dateStr].push(event);
      return groupedEvents;
    }, {});
  }

  function sortEventsByDate(grouped: any) {
    const sorted = Object.keys(grouped)
      .sort()
      .reduce((obj: any, key: string) => {
        obj[key] = grouped[key].sort((a: any, b: any) => {
          return a.start.getTime() - b.start.getTime();
        });
        return obj;
      }, {});
    return sorted;
  }

  return (
    <div
      className=""
      style={{
        margin: "12px",
        fontFamily: "'Nunito Sans', sans-serif",
        minHeight: "300px",
      }}
    >
      {Object.entries(events).map(([key, items]) => {
        return (
          <div key={key}>
            <div
              className="row border py-2"
              style={{ background: "#d1d1d14c" }}
            >
              <div className="col">{formatAppointmentDay(key)}</div>
              <div className="col text-end">{formatAppointmentDate(key)}</div>
            </div>
            {(items as any[]).map((item) => {
              return (
                <div
                  key={item.start}
                  className="row py-2 bg-light border border-bottom-0"
                >
                  <div className="col-2">
                    {formatAppointmentTme(item.start)}
                  </div>
                  <div className="col">
                    <Link
                      className="text-decoration-none"
                      to={"/leads/" + item.extendedProps.id}
                    >
                      {item.title}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default AppointmentList;
