import React, { useEffect, useState } from "react";
import { IFeature } from "../types";

const JobFeatures: React.FC<any> = (props) => {
  const job = props.job;
  const [features, setFeatures] = useState<IFeature[]>([]);

  useEffect(() => {
    const selected =
      job?.features?.filter((f: IFeature) => f.pivot.active) || [];
    setFeatures(selected);
  }, [job]);

  return (
    <div className="row">
      {features.map((feature: IFeature, index: number) => (
        <React.Fragment key={feature.id}>
          {index % 6 === 0 && <div className="row m-1"></div>}
          <div className="col-md-2">
            <div className="form-check">
              {/* <input
                className="form-check-input mr-2"
                type="checkbox"
                name="flexRadioDisabled"
                id="flexRadioDisabled"
                disabled
                checked
              /> */}
              <label
                className="form-check-label text-strong"
                htmlFor="flexRadioDisabled"
              >
                {feature.name}
              </label>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default JobFeatures;
