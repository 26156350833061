import AddIcon from "@mui/icons-material/Add";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SaveIcon from "@mui/icons-material/Save";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  formatDate,
  toDatabaseDate,
  toDatabaseDateTime,
} from "../../../common/dateUtils";
import { currencyValue } from "../../../common/utils";
import {
  createPaverOrderDelivery,
  deletePaverOrderItem,
  fetchPaverOrder,
  updatePaverOrder,
} from "../../../services/jobPavers";
import { showToaster } from "../../../services/toastService";
import ConfirmDialog from "../../common/ConfirmDialog";
import DeliveryAddDialog from "../../common/DeliveryAddDialog";
import ProgressSpinner from "../../common/ProgressSpinner";
import JobCustomerInfo from "../sidePane/JobCustomerInfo";
import PaverOrderEditBreadCrumbs from "./PaverOrderEditBreadCrumbs";
import PaverOrderItem from "./PaverOrderItem";
import { IPaverOrder, IPaverOrderItem } from "./types";

const newOrderItem: IPaverOrderItem = {
  color: "",
  size: "",
  paver: "",
  qty: "",
  qty_unit: "",
  sqft: 0,
  tumbled: 0,
  weight: 0,
  price: "",
  pallet_deposit: "",
};

const PaverOrderEdit: React.FC<any> = () => {
  const orderId = useParams().orderId;
  const [paverOrder, setPaverOrder] = useState<IPaverOrder>({} as IPaverOrder);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [state, setState] = React.useState({} as any); //ordered vs delivered
  const navigate = useNavigate();

  const { handleSubmit, register, reset, formState } = useForm({
    defaultValues: async () => {
      setLoading(true);
      const response = await fetchPaverOrder(orderId || "0");
      setLoading(false);
      if (response.error) {
        return {};
      }
      const order = response.data;
      order.order_number = order.order_number || order.id;
      setPaverOrder(order);
      return order;
    },
  });

  React.useEffect(() => {
    calculateTotalDelivered();
  }, [paverOrder]);

  function calculateTotalDelivered() {
    const delivered = paverOrder.deliveries?.reduce((total, delivery) => {
      total += currencyValue(delivery.qty || 0);
      return total;
    }, 0);

    const ordered = paverOrder.pavers?.reduce((total, paver) => {
      total += currencyValue(paver.qty);
      return total;
    }, 0);

    const _isDelivered = ordered > 0 && delivered >= ordered;
    setState({ delivered, ordered, isDelivered: _isDelivered });

    return `${delivered}/${ordered}`;
  }

  async function updateOrder(data: IPaverOrder) {
    if (!formState.isDirty || isEmpty(data)) {
      return;
    }

    setLoading(true);

    if (data.order_date) {
      data.order_date = toDatabaseDate(data.order_date);
    }
    if (data.delivery_at) {
      data.delivery_at = toDatabaseDate(data.delivery_at);
    }
    if (data.delivered) {
      data.delivered = toDatabaseDate(data.delivered);
    }

    const resp = await updatePaverOrder(orderId, data);
    if (resp.data) {
      const message = "Order updated successfully";
      toast.success(message);
      setPaverOrder(resp.data);
      reset(resp.data);
    } else {
      const message = resp.error?.message || resp.error;
      toast.error(message);
    }
    setLoading(false);
  }

  function handleAddLine() {
    setPaverOrder((prev) => ({
      ...prev,
      pavers: [...prev.pavers, newOrderItem],
    }));
  }

  const deliveryAtReg = register("delivery_at");
  const orderDateReg = register("order_date");
  const deliveredReg = register("delivered");

  function handleViewPdfClick(event: any): void {
    navigate(`/paver-orders/${paverOrder.uid}/pdf`);
  }

  function showDeleteItemConfirmation(id: any): void {
    setSelectedItemId(id);
    setShowDeleteModal(true);
  }

  async function deleteOrderItem() {
    setShowDeleteModal(false);
    const resp = await deletePaverOrderItem(selectedItemId);
    if (!resp.error) {
      const message = "Order item deleted successfully";

      const newPavers: IPaverOrderItem[] = paverOrder.pavers?.filter((item) => {
        return item.id != selectedItemId;
      });
      const newOrder = { ...paverOrder, pavers: newPavers };
      setPaverOrder(newOrder);

      reset(newOrder);

      toast.success(message);
    } else {
      const message = resp.error?.message || resp.error;
      toast.error(message);
    }
  }

  function handleAddDelivery(event: any) {
    event.preventDefault();
    console.log("handleAddDelivery");
    setState((prev: any) => ({ ...prev, showAddDeliveryModal: true }));
  }

  const deliveredButtonClass = classNames("btn dropdown-toggle", {
    "btn-default": !state.isDelivered,
    "btn-success": state.isDelivered,
  });

  async function addDelivery(data: any) {
    if (data.quantity != 0) {
      const newDelivery = {
        ...data,
        delivered_at: toDatabaseDateTime(data.delivered_at || new Date()),
        paver_id: paverOrder.id,
      };
      console.log("newDelivery: ", newDelivery);
      const resp = await createPaverOrderDelivery(paverOrder.id, newDelivery);
      if (resp.data) {
        const deliveries = [...paverOrder.deliveries, resp.data];
        setPaverOrder({ ...paverOrder, deliveries });
        const message = "Delivery added successfully";
        toast.success(message);
      } else {
        const message = resp.error || "no data";
        toast.error(message);
      }
    }
  }

  function updateState(newState: any) {
    setState((prev: any) => ({ ...prev, ...newState }));
  }

  return (
    <div className="container">
      <div className="m-1">
        <PaverOrderEditBreadCrumbs paverOrder={paverOrder} />
      </div>
      <div className="fixed-header">
        <div className="p-1">
          <Fab
            color="primary"
            variant="extended"
            className="mx-2 "
            disabled={!formState.isDirty}
            onClick={handleSubmit(updateOrder)}
          >
            <SaveIcon className="mr-1" />
            Save
          </Fab>
          <Fab variant="extended" onClick={handleViewPdfClick}>
            <PictureAsPdfIcon className="mr-1" />
            PDF
          </Fab>
        </div>
      </div>
      <ProgressSpinner show={loading} />
      <ToastContainer />
      <ConfirmDialog
        show={showDeleteModal}
        message={"Are you sure you want to delete line? "}
        onHide={() => setShowDeleteModal(false)}
        handleDeleteClick={deleteOrderItem}
      />
      <DeliveryAddDialog
        show={state.showAddDeliveryModal}
        onHide={() => updateState({ showAddDeliveryModal: false })}
        handleSaveClick={addDelivery}
        quantity={state.ordered - state.delivered}
      />

      <div>
        <div className="row p-3 border my-2">
          <div className="col-md-6">
            <JobCustomerInfo lead={paverOrder.job?.lead} />
          </div>
          <div className="col">
            <div>
              <span className="text-strong">Job Title: </span>
              {paverOrder.job?.name}
            </div>
            <div>
              <span className="text-strong">Job Number: </span>
              <Link
                to={`/jobs/${paverOrder.job?.id}`}
                className="text-decoration-none"
              >
                {paverOrder.job?.code
                  ? paverOrder.job?.code
                  : paverOrder.job?.id}
              </Link>
            </div>
            <div>
              <Link
                to={`/jobs/${paverOrder.job?.id}/pavers`}
                className="text-decoration-none"
              >
                All Orders
              </Link>
            </div>
          </div>
        </div>

        <Paper elevation={3}>
          <div className="supplier my-3 mx-2 p-3">
            <div className="row mb-1">
              <div className="col-md-2">
                <label htmlFor="manufacturer" className="form-label">
                  Supplier
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="name"
                  {...register("manufacturer")}
                  defaultValue={paverOrder.manufacturer}
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="portlands" className="form-label">
                  Number of Portlands
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("portlands")}
                  placeholder="0"
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="orderedby" className="form-label">
                  Ordered by
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("orderedby")}
                  placeholder="John"
                  defaultValue={paverOrder.orderedby}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="handledby" className="form-label">
                  Delivery Handled by
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("handledby")}
                  placeholder="Name"
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="delivery_at" className="form-label">
                  Date to Deliver
                </label>
                <DatePickerComponent
                  name={deliveryAtReg.name}
                  onChange={deliveryAtReg.onChange}
                  onBlur={deliveryAtReg.onBlur}
                  ref={deliveryAtReg.ref}
                />
              </div>
            </div>
            <div className="row mb-2 align-items-end">
              <div className="col-md-4">
                <label htmlFor="note" className="form-label">
                  Delivery Instructions:
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("note")}
                  placeholder="backyard"
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="delivery_addr" className="form-label">
                  Delivery Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("delivery_addr")}
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="order_date" className="form-label">
                  Order Date
                </label>
                <DatePickerComponent
                  name={orderDateReg.name}
                  onChange={orderDateReg.onChange}
                  onBlur={orderDateReg.onBlur}
                  ref={orderDateReg.ref}
                />
              </div>
              {false && (
                <div className="col-md-2">
                  <label htmlFor="delivered" className="form-label">
                    Delivered
                  </label>
                  <DatePickerComponent
                    name={deliveredReg.name}
                    onChange={deliveredReg.onChange}
                    onBlur={deliveredReg.onBlur}
                    ref={deliveredReg.ref}
                  />
                </div>
              )}

              <div className="col-md-2">
                <label htmlFor="order_number" className="form-label">
                  Order Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("order_number")}
                />
              </div>
            </div>
            <div className="row mb-3 align-items-end">
              <div className="col-md-3">
                <label htmlFor="invoice" className="form-label">
                  Invoice Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("invoice")}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="invoice" className="form-label">
                  Sales Tax
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("tax_rate")}
                />
              </div>
              <div className="col-md-2">
                <div className="dropdown">
                  <button
                    className={deliveredButtonClass}
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Delivery
                  </button>
                  <ul className="dropdown-menu">
                    {paverOrder.deliveries?.map((delivery) => (
                      <li>
                        <a className="dropdown-item" href="#">{`${
                          delivery.qty
                        } @${formatDate(delivery.delivered_at)}`}</a>
                      </li>
                    ))}
                    <li>
                      <a className="dropdown-item" href="#">
                        {`Total: ${state.delivered}/${state.ordered}`}
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item text-primary"
                        href="#"
                        onClick={handleAddDelivery}
                      >
                        Add Delivery
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Paper>

        <div>
          {paverOrder.pavers?.map((paver, index) => (
            <PaverOrderItem
              key={paver.id}
              item={paver}
              index={index}
              register={register}
              deleteItem={showDeleteItemConfirmation}
            />
          ))}
        </div>
        <div className="row mt-3 mb-5 px-3">
          <button className="btn btn-outline-secondary" onClick={handleAddLine}>
            <AddIcon className="mr-2" />
            Add Line
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaverOrderEdit;
