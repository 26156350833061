import React, { useEffect, useState } from "react";
import { IChecklist } from "./types";
import arrayMove from "array-move";
import { Paper, TextField } from "@mui/material";
import { BsPencilFill } from "react-icons/bs";
import { RiDraggable } from "react-icons/ri";
import ChecklistsCreateDialog from "./ChecklistsCreateDialog";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import {
  debounce,
  formatErrorMessage,
  updateDisplayOrder,
} from "../../../common/utils";
import {
  deleteChecklistItem,
  updateChecklistBulk,
} from "../../../services/checklistApiServices";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { constants } from "../../../config/constants";

interface IProps {
  items: IChecklist[];
  onListUpdated: (items: any[]) => void;
}

const sendRequest = async (data: IChecklist[]): Promise<any> => {
  const resp = await updateChecklistBulk(data);
  if (resp.data) {
    toast.success("Checklist sorted");
    console.log("sendRequest response ", resp.data);
  } else {
    console.log("sendRequest error ", resp.error);
  }
};

const debouncedSendRequest = debounce(
  async (updated: IChecklist[]) => await sendRequest(updated),
  3000
);

const ChecklistList: React.FC<IProps> = (props) => {
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [checklist, setChecklist] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedType, setSelectedType] = useState("all");

  useEffect(() => {
    setChecklist(props.items);
    setSelectOptions(props.items);
    filterList();
  }, [props.items]);

  useEffect(() => {
    filterList();
  }, [selectedType]);

  function showEditDialog(id: any): void {
    const _items = props.items.find((item: any) => item.id === id);
    setSelectedItem(_items);
    setShowModal(true);
  }

  function setSelectOptions(items: any): void {
    const types: any[] = items
      .map((item: any) => item.type)
      .filter((d: string) => !!d);
    const unique = Array.from(new Set(types));
    setOptions(unique);
  }

  function handleOnSaved(item: any): void {
    const newList = checklist.map((_item: any) => {
      if (_item.id === item.id) {
        return item;
      }
      return _item;
    });
    props.onListUpdated(newList);
  }

  function handleSearchChanged(event: any): void {
    setSearch(event.target.value);
    const searchValue = event.target.value.toLowerCase();
    const filteredList = props.items?.filter((item: any) => {
      return item.name.toLowerCase().includes(searchValue);
    });
    setChecklist(filteredList);
  }

  function handleOnSortEnd(oldIndex: number, newIndex: number): void {
    if (search.length > 0) return;
    const sorted = arrayMove(checklist, oldIndex, newIndex);
    const updatedItems = updateDisplayOrder(sorted);
    setChecklist(updatedItems);
    debouncedSendRequest(updatedItems);
  }

  async function showDeleteDialog(id: any) {
    const response = await Swal.fire({
      title: "Are you sure?",
      position: "top",
      text: "You will not be able to recover this item!",
      showCancelButton: true,
      confirmButtonColor: constants.COLOR_DANGER,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (response.isConfirmed) {
      const resp = await deleteChecklistItem(id);
      if (resp.data) {
        const newList = checklist.filter((item: any) => item.id !== id);
        props.onListUpdated(newList);
        toast.success("Item deleted");
      } else {
        toast.error("Error deleting item " + formatErrorMessage(resp.error));
      }
    }
  }

  function handleSelectionChange(event: any): void {
    setSelectedType(event.target.value);
  }

  function filterList(): void {
    if (selectedType === "all") {
      setChecklist(props.items);
      return;
    }
    const filteredList = props.items?.filter((item: any) => {
      return item.type === selectedType;
    });
    setChecklist(filteredList);
  }

  return (
    <>
      <ChecklistsCreateDialog
        item={selectedItem}
        options={options}
        show={showModal}
        onHide={() => setShowModal(false)}
        onSaved={handleOnSaved}
      />
      <div className="p-3">
        <div className="row my-3 ml-3">
          <div className="col-5">
            <TextField
              id="filled-search"
              label="Search"
              type="search"
              variant="outlined"
              onChange={handleSearchChanged}
              value={search}
            />
          </div>
          <div className="col-3">
            <select
              className="form-select form-select-sm"
              onClick={handleSelectionChange}
            >
              <option key="all" value="all">
                All
              </option>
              {options.map((option: any) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <SortableList
            onSortEnd={handleOnSortEnd}
            draggedItemClassName="item-dragged"
            style={{ width: 620 }}
          >
            {checklist.map((item) => (
              <SortableItem key={item.id}>
                <div>
                  <Paper
                    elevation={3}
                    className="p-2 my-1 ml-3 sort-item"
                    style={{ width: 600 }}
                  >
                    <div className="d-flex">
                      <SortableKnob>
                        <div>
                          <RiDraggable />
                        </div>
                      </SortableKnob>
                      <div className="flex-grow-1 mt-1 ml-3 cursor-default">
                        <div className="">{item.name}</div>
                      </div>
                      <div className="text-end">
                        {item.type && (
                          <button
                            disabled={true}
                            type="button"
                            className="btn btn-outline-danger btn-sm mr-2"
                            title="delete item"
                            onClick={() => showDeleteDialog(item.id)}
                          >
                            {item.type}
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm mr-2"
                          title="edit item"
                          onClick={() => showEditDialog(item.id)}
                        >
                          <BsPencilFill />
                        </button>
                      </div>
                    </div>
                  </Paper>
                </div>
              </SortableItem>
            ))}
          </SortableList>
        </div>
      </div>
    </>
  );
};

export default ChecklistList;
