import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchPdfString } from "../../../services/jobApiService";
import { PdfViewerToolbar } from "./PdfViewerToolbar";

interface IProps {
  model: string;
}

const PDFViewer: React.FC<IProps> = (props) => {
  const itemId = useParams().id;
  const model = props.model;
  const [pdfString, setPdfString] = useState("");

  useEffect(() => {
    fetchPdfString(itemId, model).then((resp) => {
      if (resp.data) {
        setPdfString(resp.data);
      } else {
        const error = resp.error || "no data";
        setPdfString("error: " + error);
      }
    });
  }, []);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => {
      return defaultTabs.filter((tab) => tab.title == "Thumbnail");
    },
    renderToolbar: PdfViewerToolbar,
  });

  return (
    <div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        {pdfString && (
          <div className="pdf-viewer-container">
            <Viewer
              fileUrl={pdfString}
              plugins={[defaultLayoutPluginInstance]}
              defaultScale={SpecialZoomLevel.PageFit}
            />
          </div>
        )}
      </Worker>
    </div>
  );
};

export default PDFViewer;
