import React, { useCallback, useMemo, useRef, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import { debounce } from "../../common/utils";

// **** No need to use useCallback here ****
const sendRequest = async (lang: string[]): Promise<any> => {
  console.log("Send request to server ", lang);
  await new Promise((resolve) => setTimeout(resolve, 1000));
};
const debouncedSendRequest = debounce(
  async (updated) => await sendRequest(updated),
  3000
);

const Test: React.FC = () => {
  const [languages, setLanguages] = useState([
    "JavaScript",
    "TypeScript",
    "Python",
    "Java",
    "C#",
  ]);

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const updated = arrayMove(languages, oldIndex, newIndex);
    setLanguages(updated);
    debouncedSendRequest(updated);
  };

  return (
    <div className="container">
      <SortableList
        onSortEnd={onSortEnd}
        className="sort-list"
        draggedItemClassName="bg-primary"
      >
        {languages.map((item) => (
          <SortableItem key={item}>
            <div className="sort-item bg-success text-white fw-bold m-3 p-3 rounded-1">
              {item}
            </div>
          </SortableItem>
        ))}
      </SortableList>
    </div>
  );
};

export default Test;
