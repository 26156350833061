import { QueryClient } from "@tanstack/react-query";
import { IProposalTemplate } from "../components/job/types";
import { toTitleCase } from "./utils";

export function invalidateQueries(queryClient: QueryClient, keys: any[]) {
  const queries = Array.isArray(keys) ? keys : [keys];
  queries.forEach((q: any) => {
    queryClient.invalidateQueries({
      queryKey: [q],
    });
  });
}

export function getOptionList(templates: IProposalTemplate[]) {
  const groups = templates.reduce(
    (acc: Set<string>, { group }: IProposalTemplate) => {
      const tempGroups = group?.split(",") || [];
      tempGroups.forEach((g) => acc.add(g.toLocaleLowerCase().trim()));
      return acc;
    },
    new Set<string>()
  );

  const sortedGroups = Array.from(groups)
    .map(toTitleCase)
    .sort((a, b) => a.localeCompare(b));

  return sortedGroups;
}
