import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchEmails = async (id: string) => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/emails" + (id ? "/" + id : "");
    const resp = await apiService.get(url);
    console.log("emails fetched ", resp.data?.length);
    return resp;
  } catch (err: any) {
    console.log("error on fetchEmails " + err);
    return { error: err.message };
  }
};

export const fetchEmailsByLead = async (leadId: string) => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/leads/" + leadId + "/emails";
    const resp = await apiService.get(url);
    console.log("emails fetched ", resp.data?.length);
    return resp;
  } catch (err: any) {
    console.log("error on fetchEmailsByLead " + err);
    return { error: err.message };
  }
};

export const createEmail = async (leadId: string | number, data: any) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.post(
      `${apiUrl}/leads/${leadId}/emails`,
      data
    );
    console.log("emails created ", resp.data?.length);
    return resp;
  } catch (err: any) {
    console.log("error on createEmail " + err);
    return { error: err.message };
  }
};

export const updateEmail = async (id: string, data = {}) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.put(apiUrl + "/emails/" + id, data);
    console.log("email updated", data);
    return resp;
  } catch (err: any) {
    console.log("error on updateEmail " + err);
    return { error: err.message };
  }
};

export const deleteEmail = async (id: string | number) => {
  try {
    const apiService = new ApiService();
    const resp = await apiService.delete(apiUrl + "/emails/" + id);
    console.log("email deleted ");
    return resp;
  } catch (err: any) {
    console.log("error on deleteEmail " + err);
    return { error: err };
  }
};
