import React, { useImperativeHandle } from "react";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";

interface IProps {
  position?: object;
  duration?: number;
}

interface RefType {
  show: (message: string) => void;
}

const ToastNotification: React.ForwardRefRenderFunction<RefType, IProps> = (
  props,
  ref
) => {
  const toastObjRef = React.useRef<any>(null);
  const position = props.position || { X: "Right" };
  const duration = props.duration || 200;

  useImperativeHandle(ref, () => ({
    show: (message: string) => handleShow(message),
  }));

  const handleShow = (message: string = "") => {
    setTimeout(() => {
      if (toastObjRef.current && toastObjRef.current.show) {
        toastObjRef.current.show({
          title: "Notification",
          content: message,
          icon: "e-meeting",
        });
      }
    }, duration);
  };

  return (
    <ToastComponent
      id="toast_default"
      ref={toastObjRef}
      position={position}
    ></ToastComponent>
  );
};

export default React.forwardRef(ToastNotification);
