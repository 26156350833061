import { toDatabaseDate } from "../../../common/dateUtils";
import { currencyValue } from "../../../common/utils";
import { IJob } from "../types";
import { IInstallerPayment } from "./types";

export const cleanData = (data: IInstallerPayment): IInstallerPayment => {
  const lines = data.lines?.map((line: any, index: number) => {
    return {
      ...line,
      display_order: index,
      quantity: currencyValue(line.quantity),
      rate: currencyValue(line.rate),
      total: currencyValue(line.total),
    };
  });
  return {
    ...data,
    payment_date: toDatabaseDate(data.payment_date),
    lines: lines || [],
  };
};

export const getNewPaymentData = (data: IJob): IInstallerPayment => {
  const lines: any = data.line_items?.map((line: any, index: number) => {
    return {
      quantity: currencyValue(line.quantity),
      rate: 0,
      total: 0,
      display_order: index,
      description: line.description,
    };
  });
  return {
    account_id: data.account_id,
    job_id: data.id,
    description: data.name,
    installer: "",
    lines: lines,
  };
};

export function totalInThousands(amount: any) {
  const _amount = currencyValue(amount);
  const total = _amount / 1000;
  return total.toFixed(1);
}

export function getTotalTooltip(job: IJob, total: any) {
  const jobTotal = job?.proposal_amount;
  const paymentPercent = (currencyValue(total) / currencyValue(jobTotal)) * 100;
  return paymentPercent.toFixed(2) + "% - " + totalInThousands(jobTotal);
}
