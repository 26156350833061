import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchFormInputs = async (id: string = "") => {
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/form-inputs" + (id ? "/" + id : "");
    const resp = await apiService.get(url);
    console.log("formInputs fetched ", resp.data?.length);
    return resp;
  } catch (err: any) {
    console.log("error on fetchFormInputs " + err);
    throw err;
  }
};

export const upsertFormInput = async (params: any) => {
  const { type, data } = params;
  try {
    const apiService = new ApiService();
    const url = apiUrl + "/form-inputs/" + type;
    const resp = await apiService.post(url, data);
    return resp;
  } catch (err: any) {
    console.log("error on upsertFormInput " + err);
    throw err;
  }
};
