import { Field } from "./types";

export const fieldsSettings: Field<any>[] = [
  {
    label: "Customer Name",
    key: "customer_name",
    alternateMatches: ["first name", "name", "customer", "First+ Last Names"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Name is required",
        level: "error",
      },
    ],
  },
  {
    label: "Phone",
    key: "phone",
    alternateMatches: ["phone number"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "regex",
        value: "^\\(?\\d{3}\\)?[- ]?\\d{3}[- ]?\\d{4}$",
        errorMessage: "Invalid phone number",
        level: "error",
      },
    ],
  },
  {
    label: "Secondary Phone",
    key: "secondary_phone",
    alternateMatches: [
      "secondary number",
      "secondary phone number",
      "secondary/ phone",
    ],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "regex",
        value: "^(\\(?\\d{3}\\)?[- ]?\\d{3}[- ]?\\d{4}|)$",
        errorMessage: "Invalid phone number",
        level: "info",
      },
    ],
  },
  {
    label: "Email",
    key: "email",
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "regex",
        value: "^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        errorMessage: "Invalid email address",
        level: "error",
      },
    ],
  },
  {
    label: "Street Address",
    key: "street",
    alternateMatches: ["address", "street address", "street"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Address is required",
        level: "error",
      },
    ],
  },
  {
    label: "City",
    key: "city",
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Zip Code",
    key: "zip",
    fieldType: {
      type: "input",
    },
    alternateMatches: ["zip code", "zip"],
  },
  {
    label: "Lead Date",
    key: "created_at",
    fieldType: {
      type: "input",
    },
    alternateMatches: ["date", "created at", "timestamp"],
  },
  {
    label: "State of the Project",
    key: "project_state",
    fieldType: {
      type: "input",
    },
    alternateMatches: ["project stage", "project state", "state of project"],
  },
  {
    label: "Style",
    key: "project_style",
    fieldType: {
      type: "input",
    },
    alternateMatches: [
      "style",
      "project style",
      "What Style/Look are you going for?",
    ],
  },
  {
    label: "Colors",
    key: "project_colors",
    fieldType: {
      type: "input",
    },
    alternateMatches: [
      "colors",
      "project colors",
      "What colors are you considering?",
      "Colors…?",
    ],
  },
  {
    label: "Features",
    key: "project_features",
    fieldType: {
      type: "input",
    },
    alternateMatches: ["features", "project features"],
  },
  {
    label: "Notes",
    key: "notes",
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Best Time to Meet",
    key: "best_time",
    fieldType: {
      type: "input",
    },
    alternateMatches: ["best time to meet", "best time", "availability"],
  },
  {
    label: "Availability-Mondays",
    key: "best_time_mondays",
    fieldType: {
      type: "input",
    },
    alternateMatches: [
      "best time to meet on mondays",
      "best time mondays",
      "availability mondays",
      "availability on mondays",
      "Best Times to Meet [Mondays]",
    ],
  },
  {
    label: "Availability-Tuesdays",
    key: "best_time_tuesdays",
    fieldType: {
      type: "input",
    },
    alternateMatches: [
      "best time to meet on tuesdays",
      "best time tuesdays",
      "availability tuesdays",
      "availability on tuesdays",
      "Best Times to Meet [Tuesdays]",
    ],
  },
  {
    label: "Availability-Wednesdays",
    key: "best_time_wednesdays",
    fieldType: {
      type: "input",
    },
    alternateMatches: [
      "best time to meet on wednesdays",
      "best time wednesdays",
      "availability wednesdays",
      "availability on wednesdays",
      "Best Times to Meet [Wednesdays]",
    ],
  },
  {
    label: "Availability-Thursdays",
    key: "best_time_thursdays",
    fieldType: {
      type: "input",
    },
    alternateMatches: [
      "best time to meet on thursdays",
      "best time thursdays",
      "availability thursdays",
      "availability on thursdays",
      "Best Times to Meet [Thursdays]",
    ],
  },
  {
    label: "Availability-Fridays",
    key: "best_time_fridays",
    fieldType: {
      type: "input",
    },
    alternateMatches: [
      "best time to meet on fridays",
      "best time fridays",
      "availability fridays",
      "availability on fridays",
      "Best Times to Meet [Fridays]",
    ],
  },
  {
    label: "Availability-Saturdays",
    key: "best_time_saturdays",
    fieldType: {
      type: "input",
    },
    alternateMatches: [
      "best time to meet on saturdays",
      "best time saturdays",
      "availability saturdays",
      "availability on saturdays",
      "Best Times to Meet [Saturdays]",
    ],
  },
];
