import React from "react";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";

interface IProps {
  show: boolean;
}

const ProgressSpinner: React.FC<IProps> = (props) => {
  return (
    <div className="spinner-overlay">
      {props.show && (
        <ProgressBarComponent
          id="rounded-container"
          type="Circular"
          width="80px"
          height="80px"
          isIndeterminate={true}
          value={20}
        />
      )}
    </div>
  );
};

export default ProgressSpinner;
