import { TextField } from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { createEmail } from "../../../services/emailApiService";
import { showToaster } from "../../../services/toastService";

interface IProps {
  leadId?: string | number;
  show: boolean;
  onHide: (e?: any) => void;
  onCreated: (email: any) => void;
}

const EmailAddDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = React.useState(false);

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  React.useEffect(() => {
    reset({});
    setShowModal(props.show);
  }, [props.show]);

  function handleCancelClick(event: any = null): void {
    setShowModal(false);
    props.onHide();
  }

  async function handleSaveClick(event: any) {
    const data = await triggerValidation();

    if (!isEmpty(errors) || !props.leadId) return;

    const resp = await createEmail(props.leadId, data);
    if (resp.data) {
      console.log("Saved email " + resp.data.email);
      handleCancelClick();
      props.onCreated(resp.data);
    } else {
      showToaster("Error saving email " + JSON.stringify(resp.error), "error");
    }
  }

  async function triggerValidation() {
    await handleSubmit((data: any) => data)();
    return getValues();
  }

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={props.onHide}
      className="modal-dialog-layer"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-5 my-3">
          <div className="row">
            <TextField
              {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
              label="Email"
              placeholder="joe@mail.com"
              size="small"
              fullWidth
              error={!!errors.email}
              helperText={errors.email ? "Email is invalid" : ""}
              autoComplete="off"
            />
          </div>
          <div className="row mt-4">
            <TextField
              {...register("label", { required: true })}
              label="Label"
              placeholder="work"
              size="small"
              fullWidth
              error={!!errors.label}
              helperText={errors.label ? "Label is required" : ""}
              autoComplete="off"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSaveClick} disabled={!isDirty}>
          Save
        </Button>
        <Button variant="danger" onClick={handleCancelClick}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailAddDialog;
