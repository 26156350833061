import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import React from "react";
import LinkRouter from "../common/LinkRouter";
import { IJobRepair } from "./types";

interface IProps {
  job: IJobRepair;
}

const JobRepairsBreadCrumbs: React.FC<IProps> = ({ job }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter underline="hover" color="inherit" to={`/jobs/${job?.id}`}>
        Job
      </LinkRouter>
      <Typography color="text.primary">Job Repairs</Typography>
    </Breadcrumbs>
  );
};

export default JobRepairsBreadCrumbs;
