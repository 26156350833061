import { createContext, useContext } from "react";
import { IAuthUser } from "../auth/types";

export const UserContext =
  createContext<[IAuthUser, (user: any) => void]>(null);

export function useUserContext() {
  const [user] = useContext(UserContext);
  const _user = user || ({} as any);

  return [_user];
}
