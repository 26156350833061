import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import PricesCreateDialog from "./PricesCreateDialog";

interface IProps {
  prices: any;
  type: string;
  onListUpdated: (prices: any[], type: string) => void;
}

const PricesList: React.FC<IProps> = (props) => {
  const [prices, setPrices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sortDirection, setSortDirection] = useState<any>(1);
  const [selectedPrice, setSelectedPrice] = useState<any>({});

  useEffect(() => {
    const inputs = props.prices || [];
    setPrices(inputs);
  }, [props.prices]);

  function showEditDialog(id: any): void {
    const price = props.prices.find((item: any) => item.id === id);
    setSelectedPrice(price);
    setShowModal(true);
  }

  function handleOnSaved(price: any): void {
    const newPrices = prices.map((item: any) => {
      if (item.id === price.id) {
        return price;
      }
      return item;
    });
    props.onListUpdated(newPrices, props.type);
  }

  function handleSort(e: any): void {
    e.preventDefault();
    const id = e.target.id;
    const sortedPrices = [...prices];

    sortedPrices.sort((a, b) =>
      a[id] > b[id] ? sortDirection : -sortDirection
    );
    setPrices(sortedPrices);
    setSortDirection(sortDirection * -1);
  }

  function sortList() {
    const id = "vendor";
    const sortedPrices = [...prices];

    sortedPrices.sort((a, b) =>
      a[id] > b[id] ? sortDirection : -sortDirection
    );
    setPrices(sortedPrices);
  }

  return (
    <>
      <PricesCreateDialog
        price={selectedPrice}
        type={props.type}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <div className="row p-5 pt-3" style={{ maxWidth: 800 }}>
        <table className="table table-striped">
          <thead className="table-dark">
            <tr>
              <th>
                <a
                  href="#"
                  id="vendor"
                  onClick={handleSort}
                  className="text-decoration-none text-white"
                >
                  Supplier
                </a>
              </th>
              <th>
                <a
                  href="#"
                  id="price"
                  onClick={handleSort}
                  className="text-decoration-none text-white"
                >
                  Price
                </a>
              </th>
              <th>Update</th>
            </tr>
          </thead>
          <tbody>
            {prices.map((item: any) => (
              <tr key={item.id}>
                <th scope="row">{item.vendor}</th>
                <td>{item.price}</td>
                <td>
                  <a
                    href="#"
                    className="btn btn-default"
                    onClick={() => showEditDialog(item.id)}
                  >
                    <BsPencilSquare />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PricesList;
