import { DateTime } from "luxon";
import { constants } from "./../config/constants";

export function formatAppointmentTme(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone(constants.DEFAULT_TIMEZONE)
    .toFormat("h:mma");
}

export function formatAppointmentDate(date: string): string {
  return DateTime.fromISO(date)
    .setZone(constants.DEFAULT_TIMEZONE)
    .toFormat("MMM d, yyyy");
}

export function formatAppointmentDay(date: string): string {
  return DateTime.fromISO(date)
    .setZone(constants.DEFAULT_TIMEZONE)
    .toFormat("EEEE");
}

export const toDatabaseDate = (date: any) => {
  if (!date) return "";
  const _date = toJSDate(date);
  return DateTime.fromJSDate(_date).toISODate();
};

export const toDatabaseDateTime = (date: any) => {
  if (!date) return "";
  const _date = toJSDate(date);
  return DateTime.fromJSDate(_date)
    .setZone(constants.DEFAULT_TIMEZONE, { keepLocalTime: true })
    .toUTC()
    .toISO();
};

export const toJSDate = (date: any) => {
  if (!date) return null;
  if (date instanceof Date) return date;
  if (typeof date === "string") {
    if (date.length === 10) {
      date += " 00:00:00";
    }
  }
  return new Date(date);
};

export const ensureDate = (date: any) => {
  if (date instanceof Date) {
    return date;
  }

  if (typeof date === "string") {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
  }

  return null;
};

export const formatDate = (date: string) => {
  if (!date) return "";
  let d = DateTime.fromISO(date);
  if (!d.isValid) {
    d = DateTime.fromSQL(date);
  }
  return d.isValid ? d.toFormat("MMM dd, yyyy") : "";
};

export const fromDatabaseDateTime = (date: any) => {
  if (date) {
    const time = DateTime.fromISO(date)
      .setZone(constants.DEFAULT_TIMEZONE)
      .toFormat("yyyy-LL-dd HH:mm:ss");
    return time;
  }
  return null;
};

export const formatDateTime = (date: any, relative = true) => {
  let d;

  if (typeof date === "string" && date.endsWith("Z")) {
    d = DateTime.fromISO(date).setZone("local");
  } else {
    d = DateTime.fromSQL(date, { zone: "utc" }).setZone("local");
  }

  if (!d.isValid) return date;
  const diff = DateTime.now().diff(d, "hours").hours;
  if (diff > 5 || !relative) {
    return d.toFormat("MMM dd, yyyy 'at' h:mm a");
  }
  return d.toRelative();
};
