import React, { useEffect, useState } from "react";
import {
  fetchIntegrations,
  updateGoogleMapsAPIKey,
} from "../../../services/integrationsApiService";
import { toast, ToastContainer } from "react-toastify";
import { formatErrorMessage } from "../../../common/utils";
import { QueryClient } from "@tanstack/react-query";
import { invalidateQueries } from "../../../common/dataUtils";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";

interface Props {
  name?: string;
}

const integrationName = "google-maps";

const GoogleMapsSettings: React.FC<Props> = () => {
  const [apiKey, setApiKey] = useState("");

  const queryClient = new QueryClient();

  useEffect(() => {
    fetchIntegrations(integrationName).then((res) => {
      if (res.data[0]) {
        const integration = res.data[0];
        setApiKey(integration.settings?.apiKey);
      }
    });
  }, []);

  async function handleSaveSettings() {
    try {
      await updateGoogleMapsAPIKey(apiKey);
      invalidateQueries(queryClient, ["user"]);
      toast.success("API key updated successfully");
    } catch (error: any) {
      toast.error("Error occurred: " + formatErrorMessage(error.message));
      console.error("Error occurred:", error.message);
    }
  }

  const breadcrumbLinks = [
    { label: "Settings", to: "/settings" },
    { label: "Integrations", to: "/settings/integrations" },
  ];

  return (
    <>
      <ToastContainer />
      <div className="container">
        <BreadcrumbsComponent links={breadcrumbLinks} leaf="Google Maps" />
        <h3>Google Maps Settings</h3>
        <div className="my-3">
          <div>
            Connect your Google Maps account to Leads to enable map features.
          </div>
          <div className="p-2">
            <div>
              <strong>Important:</strong> You must have a valid Google Maps API
              key to connect.
            </div>
            <p>
              <a
                href="https://developers.google.com/maps/documentation/javascript/get-api-key"
                target="_blank"
                rel="noreferrer"
              >
                Get a Google Maps API key
              </a>
            </p>
          </div>
          <p>
            <strong>API Key</strong>:
            <input
              type="text"
              className="form-control mt-1"
              style={{ width: 600 }}
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
          </p>
          <button
            className="mt-3 btn btn-outline-primary"
            onClick={handleSaveSettings}
          >
            Save Settings
          </button>
        </div>
      </div>
    </>
  );
};

export default GoogleMapsSettings;
