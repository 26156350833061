import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { tinyApiKey } from "../../../config/app";
import { dialogTinyInit } from "../../job/config";
import { IProposalTemplate } from "../../job/types";
import { createTemplate } from "../../../services/proposalApiService";
import { isEmpty } from "lodash";
import { showToaster } from "../../../services/toastService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatErrorMessage } from "../../../common/utils";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaQuestionCircle } from "react-icons/fa";

interface IProps {
  isOpen: boolean;
  onDialogClosed: () => void;
  template: IProposalTemplate;
  onTemplateUpdated: (template: IProposalTemplate) => void;
}

const CreateTemplateDialog: React.FC<IProps> = (props) => {
  const [state, setState] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  let editorRef = useRef<any>(null);

  const { register, reset, getValues, setValue } = useForm();
  const { mutateAsync } = useMutation({
    mutationFn: createTemplate,
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    resetForm();
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const buttons = [
    {
      click: () => {
        if (isValidForm()) {
          setIsOpen(false);
          handleSaveTemplate();
        }
      },
      buttonModel: {
        isPrimary: true,
        content: "Save",
        type: "submit",
      },
    },
  ];

  const handleCloseDialog = (): void => {
    setIsOpen(false);
    props.onDialogClosed();
  };

  const dialogOpen = (): void => {
    setIsOpen(true);
  };

  const isValidForm = () => {
    const { text, type } = getValues();
    if (!text) {
      setState({ errors: { text: "Text is required" } });
      return false;
    }
    if (!type) {
      setState({ errors: { type: "Type is required" } });
      return false;
    }
    return true;
  };

  function handleEditorChange(value: string): void {
    setValue("text", value, { shouldDirty: true });
  }

  function resetForm() {
    setState({});
    if (isEmpty(props.template)) {
      reset({
        text: "",
        type: "line-item",
        rate: 0,
        rate_unit: "",
      });
      editorRef?.current?.setContent("");
      return;
    }
    reset(props.template);
  }

  async function handleSaveTemplate() {
    handleCloseDialog();

    const data = getValues();

    try {
      const resp: any = await mutateAsync(data);
      queryClient.invalidateQueries({ queryKey: ["proposal-templates"] });
      const msg = props.template?.id ? "updated: " : "created: ";
      showToaster(`Template ${msg}, id: ${resp.data.id}`);
    } catch (err: any) {
      const msg = formatErrorMessage(err.error);
      showToaster("Error saving template " + msg, "error");
    }
  }

  return (
    <div className="control-section modal-dialog-target">
      <div id="target">
        <DialogComponent
          id="templateModalDialog"
          showCloseIcon={true}
          isModal={true}
          buttons={buttons}
          header="Proposal Template"
          width="800px"
          target="#target"
          visible={isOpen}
          open={dialogOpen}
          close={handleCloseDialog}
        >
          <div>
            <div className="row">
              <div className="form-group px-4">
                <label htmlFor="text" className="form-label fw-semibold fs-6">
                  Text
                </label>
                <div className="" style={{ width: 740 }}>
                  <Editor
                    apiKey={tinyApiKey}
                    initialValue={props.template.text || " "}
                    init={dialogTinyInit}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    onEditorChange={handleEditorChange}
                  />
                </div>
                {state.errors?.text && (
                  <span className="text-danger">{state.errors?.text}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group pt-3 px-3">
                  <label htmlFor="rate" className="form-label fw-semibold">
                    Unit Price
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    autoComplete="off"
                    spellCheck="false"
                    {...register("rate")}
                  />
                  {state.errors?.rate && (
                    <span className="text-danger">{state.errors.rate}</span>
                  )}
                </div>
              </div>
              <div className="col">
                <div className="form-group p-3 px-4">
                  <label htmlFor="rate_unit" className="form-label fw-semibold">
                    Unit
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="sqft"
                    spellCheck="false"
                    {...register("rate_unit")}
                  />
                  {state.errors?.rate_unit && (
                    <span className="text-danger">
                      {state.errors.rate_unit}
                    </span>
                  )}
                </div>
              </div>
              <div className="col">
                <div className="form-group p-3 px-4">
                  <label htmlFor="type" className="form-label fw-semibold">
                    Type
                  </label>
                  <div>
                    <select className="form-select" {...register("type")}>
                      <option value="line-item">Line Item</option>
                      <option value="note">Note</option>
                    </select>
                  </div>
                  {state.errors?.type && (
                    <span className="text-danger">{state.errors.type}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group p-3 px-4">
                  <label htmlFor="group" className="form-label fw-semibold">
                    Group
                    <span>
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Group is used to categorize templates
                         Separate names with comma."
                      >
                        <FaQuestionCircle
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginBottom: "2px",
                          }}
                        />
                      </a>
                      <ReactTooltip place="top" id="my-tooltip" />
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Group"
                    spellCheck="false"
                    {...register("group")}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogComponent>
      </div>
    </div>
  );
};

export default CreateTemplateDialog;
