import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IJobRepair, IRepair } from "./types";
import { DateTime } from "luxon";

interface IProps {
  show: boolean;
  job: IJobRepair;
  onClose: () => void;
  onCreate: (repair: IRepair) => void;
}

const RepairCreateDialog: React.FC<IProps> = (props) => {
  const job = props.job || ({} as IJobRepair);
  const repair = {
    contact: job.lead?.contact_name,
    phone: job.lead?.phone,
    crew: job.crew,
    note: "",
    called_at: DateTime.now().toISODate(),
  };

  const forms = useForm();

  useEffect(() => {
    forms.reset(repair);
  }, [props.show]);

  const handleOnClose = () => {
    forms.reset({ contact: "", phone: "", crew: "", note: "" });
    props.onClose();
  };

  const handleSaveRepair = async (data: IRepair) => {
    console.log(data);
    handleOnClose();
    props.onCreate(data);
  };

  return (
    <>
      <Modal show={props.show} onHide={handleOnClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Repair</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <label>Contact:</label>
              <input
                type="text"
                className="form-control"
                {...forms.register("contact")}
              />
            </div>
            <div className="col-md-6">
              <label>Phone:</label>
              <input
                type="text"
                className="form-control"
                {...forms.register("phone", { required: true })}
              />
              <div className="form-text text-danger">
                {forms.formState.errors.phone && "Phone is required."}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label>Crew:</label>
              <input
                type="text"
                className="form-control"
                {...forms.register("crew")}
              />
            </div>
            <div className="col-md-6">
              <label>Date Called:</label>
              <input
                type="date"
                className="form-control"
                {...forms.register("called_at", { required: true })}
              />
              <div className="form-text text-danger">
                {forms.formState.errors.called_at && "Date Called is required."}
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-12">
              <label>Description:</label>
              <textarea
                className="form-control"
                rows={3}
                {...forms.register("note", { required: true })}
              ></textarea>
              <div className="form-text text-danger mb-2">
                {forms.formState.errors.note && "Note is required."}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            disabled={!forms.formState.isDirty}
            onClick={forms.handleSubmit(handleSaveRepair)}
          >
            Save
          </button>
          <button className="btn btn-secondary" onClick={handleOnClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RepairCreateDialog;
