import {
  ColumnDirective,
  ColumnsDirective,
  KanbanComponent,
} from "@syncfusion/ej2-react-kanban";
import React, { useEffect, useState } from "react";
import { formatErrorMessage } from "../../../common/utils";
import {
  fetchProgressGroups,
  updateJobProgress,
} from "../../../services/jobApiService";
import "./kanban.css";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IJobProgress } from "./types";

const JobProgress: React.FC = () => {
  const [progressGroups, setProgressGroups] = useState([]);
  const navigate = useNavigate();
  let draggedFrom = "";

  const { data, isError, error } = useQuery({
    queryKey: ["progressGroups"],
    queryFn: fetchProgressGroups,
    staleTime: 1000 * 60 * 5,
  });

  const { mutateAsync } = useMutation({
    mutationFn: updateJobProgress,
  });

  useEffect(() => {
    processData(data);
    if (isError) {
      console.error("Error occurred:", error.message);
    }
  }, [data, error]);

  function processData(resp: any) {
    const { data: respData, error } = resp || {};
    if (respData) {
      const kData = Object.keys(respData).flatMap((key) => {
        return respData[key].map((item: IJobProgress) => ({
          Status: key,
          Id: item.id + "",
          Number: item.code || "ID-" + item.id,
          Summary: item.lead.customer_name,
          Type: "Story",
          RankId: item.id,
        }));
      });

      setProgressGroups(kData);
    } else if (error) {
      console.error("fetchData error: ", formatErrorMessage(error));
    }
  }

  function onDialogOpen(args: any) {
    args.cancel = true;
    navigate(`/jobs/${args.data.Id}`);
  }

  async function onDragStop(args: any) {
    const data = {
      job_id: args.data[0].Id,
      status: args.data[0].Status,
      old_status: draggedFrom,
    };

    try {
      await mutateAsync(data);
    } catch (error: any) {
      console.log("onDragStop error: ", formatErrorMessage(error.message));
    }
    console.log("onDragStop to: ", args.data[0].Status);
  }

  function onDragStart(args: any) {
    draggedFrom = args.data[0].Status;
  }

  return (
    <div className="p-3" style={{}}>
      <div className="d-flex justify-content-between">
        <div className="page-title">Jobs Progress</div>
        <div>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => navigate("/jobs/progress/map")}
          >
            Map View
          </button>
        </div>
      </div>
      <KanbanComponent
        id="kanban"
        keyField="Status"
        dataSource={progressGroups}
        cardSettings={{ contentField: "Summary", headerField: "Number" }}
        dialogOpen={onDialogOpen}
        dragStop={onDragStop}
        dragStart={onDragStart}
        enablePersistence={true}
        height="90vh"
        width={1600}
      >
        <ColumnsDirective>
          <ColumnDirective headerText="Just Sold" keyField="Just Sold" />
          <ColumnDirective headerText="On Hold" keyField="On Hold" />
          <ColumnDirective headerText="To Start" keyField="To Start" />
          <ColumnDirective headerText="In Progress" keyField="In Progress" />
          <ColumnDirective headerText="Clean Up" keyField="Clean Up" />
          <ColumnDirective
            headerText="Waiting Payment"
            keyField="Waiting for Final Payment"
          />
          <ColumnDirective
            headerText="Completed"
            keyField="Paid+Completed"
            allowToggle={true}
            isExpanded={false}
          />
        </ColumnsDirective>
      </KanbanComponent>
    </div>
  );
};

export default JobProgress;
