import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React from "react";
import LinkRouter from "../../common/LinkRouter";
import { IPaverOrder } from "./types";

interface IProps {
  paverOrder: IPaverOrder;
}

const PaverOrderEditBreadCrumbs: React.FC<IProps> = ({ paverOrder }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter
        underline="hover"
        color="inherit"
        to={`/jobs/${paverOrder.job?.id}`}
      >
        Job
      </LinkRouter>
      <LinkRouter
        underline="hover"
        color="inherit"
        to={`/jobs/${paverOrder.job?.id}/pavers`}
      >
        Paver Orders
      </LinkRouter>
      <Typography color="text.primary">Order</Typography>
    </Breadcrumbs>
  );
};

export default PaverOrderEditBreadCrumbs;
